import React, { useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';


function DlgDisplayListFranchise({showFranchiseDialogue, setFranchiseDialogueList, person}) {
    const [processing, setProcessing]                            = useState(false);

    const [franchiseList, setFranchiseList]                           = useState([]);


    useEffect(() => {
        collectFranchiseListings();
    }, [])

    /////////____
    const displayUpgradingStatusColumn = (rowData) => {     
        return rowData.rsvp.content.upgrade ? <div className="rsvp">Upgraded Accommodation</div>: <div className="rsvp"> Standard Accommodation</div>;
    }
    /////////____
    const displayPCDTColumn = (rowData) => {
      var pcdtText = '';
      if(rowData.rsvp.content.PCDT.pcdt_pharmacist == "Yes"){
        pcdtText = "PCDT ";
        const attending = rowData.rsvp.content.PCDT.pcdt_clinic == "Yes" ? "Attending Clinic" : "Not attending Clinic";
        pcdtText = pcdtText + " (" + attending + ")";
      }else {
        pcdtText = 'N/A';
      }
      return pcdtText;
    }
    /////////____
    const collectFranchiseListings = async () => {
        try{

            const resFranchise = await axios.get(CONSTANTS.API_URL +"users/franchise/list/", {
                headers: {
                     token: "Bearer "+ person.accessToken
                 }
              });

              setFranchiseList(resFranchise.data);
        }catch(err){
            console.log(err);
        }
    }

    
  return (
    <Dialog header="Franchise List" visible={showFranchiseDialogue} onHide={() => setFranchiseDialogueList(false)} style={{ width: '65vw' }}
        footer={
        <div>      
            <Button className="btn btn-picky" label="Close" onClick={() => setFranchiseDialogueList(false)} />
        </div>
    }>
      <div className="modal-body-content">
        <div className="listing-container model-in-table">

              {
                  franchiseList && (
                    <DataTable
                      value={franchiseList}
                    
                      className="min-w-full"
                      paginator rows={20} rowsPerPageOptions={[20, 30, 40]}
                      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
                      responsiveLayout="scroll"
                    >
                        <Column field="name" header="Name"></Column>
                        <Column field="surname" header="Surname"></Column>
                        <Column field="rsvp.content.pharmacy_store" header="Store"></Column>  
                        <Column field="rsvp.content.flight" header="Flight"></Column>   
                        <Column field="rsvp.content.golf" header="Golf"></Column>     
                        <Column header="PCDT" body={displayPCDTColumn}></Column>   
                        <Column header="Sharing" body={displayUpgradingStatusColumn}></Column>               
                    </DataTable>
                  )
              }
        </div>
      {
            processing && (
                <div className="img-source">
                   <img src={CONSTANTS.ORANGE_PROCESSING} className="proc" />
                </div>
            )
        }
      </div>
    </Dialog>
  )
}

export default DlgDisplayListFranchise