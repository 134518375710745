import React, { useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { toast } from 'react-toastify';

function DlgCreateCompetition({showCreateCompetition, setShowCreateCompetition, person, currentEvent, currentSupplierList}) {

    const [processing, setProcessing]                            = useState(false);
    const [optionCompetition, setOptionCompetition]              = useState([]);

    const [selectedCompValue, setSelectedCompValue]              = useState("Event"); 

    const nameRef                                                = useRef();
    const winningImageRef                                        = useRef();
    const supplierIDRef                                          = useRef();

    const handleCompetitionChange = (event) => {   
        console.log(event.target.value); 
        setSelectedCompValue(event.target.value); // Update state with the selected value
    }

    async function HandleCreatingCompetitions(e) {
        e.preventDefault();  
        setProcessing(true);   
       
        try{
      
          if(winningImageRef.current.files[0]){
    
            const formData = new FormData();
            formData.append('competitionurl',  winningImageRef.current.files[0]);
    
              const response = await axios.post(CONSTANTS.UPLOAD_MEVPHONE, formData);    
          
              if(response.status == 200){
                
                const objectbanner = {
                  "prize_url"   : response.data.imageUrl,
                  "name"        : nameRef.current.value,
                  "eventid"     : currentEvent.event_code, 
                  "comp_type"  : selectedCompValue,
                  "comp_supplierid"  : selectedCompValue == "Supplier" ? supplierIDRef.current.value : "",          
                }
    
                const content = await axios.post(CONSTANTS.API_URL+"events/competition/creation", objectbanner, {
                  headers: {
                       token: "Bearer "+ person.accessToken
                   }
                });
    
               // console.log(content.data);
    
                e.target.reset();
                toast.success("You successfully created competition successfully.");
              }else {
                toast.error("Something went wrong with uploading the image, please try again later ");
               
              }
          }
           //setUserFound(false);
           setProcessing(false);   
          // 
        } catch (err){
          console.log(err);
          setProcessing(false);   
        }  
      

    }
  return (
    <Dialog header="Create Competition" visible={showCreateCompetition} onHide={() => setShowCreateCompetition(false)} style={{ width: '50vw' }}
        footer={
            <div>      
                <Button className="btn btn-picky" label="Close" onClick={() => setShowCreateCompetition(false)} />
            </div>
        }>
        <div className="modal-body-content">
           <h2 className="lbl-title-page">Create Competition</h2>
            <div className="form-item form-Even">  
              <form onSubmit={HandleCreatingCompetitions}>                   
                    <div className="form-group">
                          <div className="lbldesc">Title:</div>
                          <input type="text" className="form-control wide100" ref={nameRef} required/>
                    </div>
                    <div className="form-group">
                          <div className="lbldesc">Competition Type:</div>
                          <select className="form-control wide100" onChange={handleCompetitionChange}>
                             <option value="Event">Event</option>
                             <option value="Supplier">Supplier</option>
                          </select>
                    </div>
                    {
                        (selectedCompValue == "Supplier") && (
                            <div className="form-group">
                                <div className="lbldesc">Supplier:</div>
                                <select className="form-control wide100" ref={supplierIDRef}>
                                   {
                                    currentSupplierList && (
                                        currentSupplierList.map((supp, index) => {
                                            return <option value={supp._id} key={index}>{supp.name}</option>
                                        })
                                    )
                                   } 
                                </select>
                            </div> 
                        )

                    }
                    <div className="form-group">
                          Select image to upload <small>(2 mb)</small>:
                        <input type="file" ref={winningImageRef} accept="image/*" required/>
                    </div>
   
                    <div className="form-bt-row">
                        <button type="submit" className="btn btn-rescure" disabled={processing}>Create</button>                             
                    </div>
            </form>
            </div>
            
        {
            processing && (
                <div className="img-source">
                   <img src={CONSTANTS.ORANGE_PROCESSING} className="proc" />
                </div>
            )
        }
        </div>
    </Dialog>
  )
}

export default DlgCreateCompetition