import React, { useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { toast } from 'react-toastify';
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import { faThumbsUp, faThumbsDown, faUsers , faAdd} from '@fortawesome/free-solid-svg-icons'

function DlgCreateEvent({showEvenCreation, setShowEvenCreate, person, userList, evenMade, setEvenMade}) {


  const [processing, setProcessing]                            = useState(false);
  const [msgFeed, setMsgFeed]                                  = useState('');
  const [msgFeedClass, setMsgFeedClass]                        = useState('');
  const [filteredUsers, setFilteredUsers]                      = useState([]);
  const [selectedUser, setSelectedUser]                        = useState(null);
  const [userfound, setUserFound]                              = useState(false);

  const [colorString, setColorString]                          = useState("#ce2030");

  const nameRef                                = useRef();
  const streetRef                              = useRef();  
  const townRef                                = useRef();
  const cityRef                                = useRef();  
  const zipCodeRef                             = useRef();
  const eventDateRef                           = useRef(null);
  const eventDateEndRef                        = useRef();
  const colorWorkRef                           = useRef();
  const eventStartTimeRef                      = useRef();
  const eventImageToUploadRef                  = useRef();

  const conferenceTypeRef                      = useRef();

  const handleColorChange = () => {
    const newColor = colorWorkRef.current.value;
    setColorString(newColor);
  };

  async function HandleCreatingEvent(e) {
    e.preventDefault();  
    setProcessing(true);   

    try{
  
      if(eventImageToUploadRef.current.files[0]){

        const formData = new FormData();
        formData.append('img_url',  eventImageToUploadRef.current.files[0]);

          const response = await axios.post(CONSTANTS.UPLOAD_MEVPHONE, formData);    
      
          if(response.status == 200){
            setMsgFeedClass("");
            const objectEvent = {
              "img_url"         : response.data.imageUrl,
              "name"            : nameRef.current.value,
              "event_date"      : eventDateRef.current.value,
              "event_time"      : eventStartTimeRef.current.value,
              "end_date"        : eventDateEndRef.current.value,
              "event_code"      : makeid(6),
              "colorcode"       : colorWorkRef.current.value,
              "street"          : streetRef.current.value,
              "town"            : townRef.current.value,
              "city"            : cityRef.current.value,
              "zipcode"         : zipCodeRef.current.value,      
              "conference_type" : conferenceTypeRef.current.value     
            }

            const content = await axios.post(CONSTANTS.API_URL+"events/creation", objectEvent, {
              headers: {
                   token: "Bearer "+ person.accessToken
               }
            });

           // console.log(content.data);
            setMsgFeed("Successully added Event.");
            setMsgFeedClass("alert-success");
            e.target.reset();
            toast.success("You successfully created an Event.");
          }else {
            setMsgFeed("Something went wrong with uploading the image, please try again later ");
            setMsgFeedClass("alert-success");
          }
      }
  
       //setUserFound(false);
       setProcessing(false);   
      // 
    } catch (err){
      console.log(err);
      setProcessing(false);   
    }       
  }


  const handleSelectUser = (user) => {
    console.log("+++++++++++++++");
    console.log(user);
    setSelectedUser(user);
    setFilteredUsers([]);
    setUserFound(true);
    // Do something with the selected user
    // For instance, you can set it in the state of your parent component
  };

  ///////////////////////////////////////////////////////////////////////
  function makeid(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
          counter += 1;
        }
        return result;
  }

  return (
    <Dialog header="Add Event" visible={showEvenCreation} onHide={() => setShowEvenCreate(false)} style={{ width: '50vw' }}
      footer={
      <div>      
          <Button className="btn btn-picky" label="Close" onClick={() => setShowEvenCreate(false)} />
      </div>
      }>
    <div className="modal-body-content">
      <div className="form-item form-Even">                

            <form onSubmit={HandleCreatingEvent}>                   
                    <div className="form-group">
                          <div className="lbldesc">Title:</div>
                          <input type="text" className="form-control wide100" ref={nameRef} required/>
                    </div>
                    <div className="form-group">
                          Select image to upload <small>(2 mb)</small>:
                        <input type="file" ref={eventImageToUploadRef} multiple="" accept="image/*" required/>
                    </div>
                    <div className="flexme">
                        <div className="form-group flexwidth50 pd10">
                              <div className="lbldesc">Street:</div>
                              <input type="text" className="form-control wide100" ref={streetRef} required/>
                        </div>                    
                  
                        <div className="form-group flexwidth50 pd10">
                              <div className="lbldesc">Town:</div>
                              <input type="text" className="form-control wide100" ref={townRef} required/>
                        </div> 
                    </div> 
                    <div className="flexme">
                        <div className="form-group flexwidth50 pd10">
                              <div className="lbldesc">City:</div>
                              <input type="text" className="form-control wide100" ref={cityRef} />
                        </div>
                        <div className="form-group flexwidth50 pd10">
                              <div className="lbldesc">ZipCode:</div>
                              <input type="text" className="form-control wide100" ref={zipCodeRef} />                                
                        </div>
                    </div>  
                
                    <div className="flexme">
                        <div className="form-group flexwidth50 pd10">
                              <div className="lbldesc">Event Date:</div>
                              <input type="date" className="form-control wide100" ref={eventDateRef}  required/>
                        </div>
                        <div className="form-group flexwidth50 pd10">
                              <div className="lbldesc">Start time:</div>
                              <input type="time" className="form-control wide100" ref={eventStartTimeRef} />                                
                        </div>
                    </div> 
                    <div className="form-group">
                          <div className="lbldesc">Event End Date:</div>
                          <input type="date" className="form-control wide100" ref={eventDateEndRef} required/>
                    </div>
                    <div className="form-group">
                          <div className="lbldesc">Conference Type Text <sub>(User types that should see this conference)</sub>:
                          </div>
                          <input type="text" className="form-control wide100" ref={conferenceTypeRef} required/>
                    </div>
                    
                    <div className="sub-color">   
                        <div className="form-group sl-mset ">
                            Color:
                            <input type="color" 
                                ref={colorWorkRef}
                                onChange={handleColorChange}
                                className="form-control " value={colorString} required/>
                        </div>
                       
                      </div>
                     <div className="form-bt-row">
                            <button type="submit" className="btn btn-rescure" disabled={processing}>Create</button>                             
                      </div>
            </form>
      </div>

        <div className="fl-position form-set-color">
            <div>Default Color</div>
            <button onClick={() =>   setColorString("#ce2030")} className="btn btn-gray">SET</button>
        </div>
        {
            processing && (
                <div className="img-source">
                   <img src={CONSTANTS.ORANGE_PROCESSING} className="proc" />
                </div>
            )
        }
        <div className="msg-back mgtop10">
           {
            msgFeedClass && (
              <div className={"alert " + msgFeedClass}>
                {msgFeed}
               </div>
            )
           }
        </div>
      </div>
    </Dialog>
  )
}

export default DlgCreateEvent