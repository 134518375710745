import React, { useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { toast } from 'react-toastify';

function DlgCreateFlight({showCreateFlightDetails, setShowCreateFlightDetails, processing, setProcessing, person}) {


    const [msgFeed, setMsgFeed]                                  = useState('');
    const [msgFeedClass, setMsgFeedClass]                        = useState('');

    const provinceRef                            = useRef();
    const titleRef                               = useRef();
    const initialRef                             = useRef();

    const travelDate1Ref                             = useRef();
    const travelTime1Ref                             = useRef();
    const travelLoc1Ref                              = useRef();

    const travelDate2Ref                             = useRef();
    const travelTime2Ref                             = useRef();
    const travelLoc2Ref                              = useRef();

    const travelDate3Ref                             = useRef();
    const travelTime3Ref                             = useRef();
    const travelLoc3Ref                              = useRef();

    async function HandleCreatingFlightData(e) {
        e.preventDefault();  
        setProcessing(true);   
    
        try{
            const flightsContent = {
                "title" : titleRef.current.value,
                "province" : provinceRef.current.value,
                "initial": initialRef.current.value,
                "flightoption": []
               }

               const travel1 = {
                    "travelDate"  : travelDate1Ref.current.value,
                    "travelTime"  : travelTime1Ref.current.value,
                    "travelLocation"  : travelLoc1Ref.current.value,
               }
               flightsContent.flightoption.push(travel1);
              
               const travel2 = {
                    "travelDate"  : travelDate2Ref.current.value,
                    "travelTime"  : travelTime2Ref.current.value,
                    "travelLocation"  : travelLoc2Ref.current.value,
               }
            
               console.log("Travel 2");
               console.log(flightsContent);
               if(travelDate2Ref.current.value.length > 0 && (travelTime2Ref.current.value.length > 0) ){
                flightsContent.flightoption.push(travel2);
               }


               const travel3 = {
                "travelDate"  : travelDate3Ref.current.value,
                "travelTime"  : travelTime3Ref.current.value,
                "travelLocation"  : travelLoc3Ref.current.value,
               }
        
               if((travelDate3Ref.current.value.length > 0) && (travelTime3Ref.current.value.length > 0) ){
                flightsContent.flightoption.push(travel3);
               }

               /*
               console.log("Travel 3");
               console.log("((((((((((((((((()))))))))))))))))");
               console.log(flightsContent);
               */
               
               const responseReg = await axios.post(CONSTANTS.API_URL+"events/flight/creation", flightsContent, {
                    headers: {
                        token: "Bearer "+ person.accessToken
                    }
                });
               console.log(responseReg.data);
               
               e.target.reset();
             
               toast.success("You successfully created a flight.");
               
               setProcessing(false);
        } catch (err){
            toast.error("Something went wrong creating a flight.");
          console.log(err);
          setProcessing(false);
       
        }       
      }



  return (
    <Dialog header="Create Flight" visible={showCreateFlightDetails} onHide={() => setShowCreateFlightDetails(false)} style={{ width: '50vw' }}
        footer={
        <div>      
            <Button className="btn btn-picky" label="Close" onClick={() => setShowCreateFlightDetails(false)} />
        </div>
        }>
    <div className="modal-body-content">
      <div className="data-item list-flight">
          <form onSubmit={HandleCreatingFlightData}>
                   
                    <div className="form-group">
                          <div className="lbldesc">Airpot Departure*:</div>
                          <input type="text" className="form-control wide100" ref={titleRef}  required/>
                    </div>     
                    <div className="form-group">
                          <div className="lbldesc">Airpot Province:</div>
                         
                          <select  className="form-control wide100" ref={provinceRef}>
                            <option value="Eastern Cape">Eastern Cape</option>
                            <option value="Free State">Free State</option>
                            <option value="Gauteng">Gauteng</option>
                            <option value="KwaZulu-Natal ">KwaZulu-Natal </option>
                            <option value="Limpopo">Limpopo</option>
                            <option value="Mpumalanga">Mpumalanga</option>
                            <option value="Northern Cape ">Northern Cape </option>
                            <option value="North West ">North West </option>
                            <option value="Western Cape ">Western Cape </option>
                          </select>
                    </div>   
                    <div className="form-group">
                          <div className="lbldesc">Airpot Inital:</div>
                          <input type="text" className="form-control wide100" ref={initialRef}  />                         
                    </div>   
                    <div className="flexme">
                        <div className="flexwidth33">
                            <p className="opt">Option 1</p>
                            <div className="form-group fr-dt-item">
                                <div className="lbldesc">Airpot Date*:</div>
                                <input type="date" className="form-control wide100" ref={travelDate1Ref}  required/> 
                            </div>  
                            <div className="form-group fr-dt-item">
                                <div className="lbldesc">Airpot Period*:</div>                                
                                <select className="form-control wide100" ref={travelTime1Ref} >
                                    <option value="Morning Flight">Morning Flight</option>
                                    <option value="Evening Flight">Evening Flight</option>
                                </select>
                            </div>  
                            <div className="form-group fr-dt-item">
                                <div className="lbldesc">Airpot Location Arrival:</div>
                                <input type="text" className="form-control wide100" ref={travelLoc1Ref}  /> 
                            </div>  
                        </div>

                        <div className="flexwidth33 fl-mid-block">
                            <p className="opt">Option 2</p>
                            <div className="form-group fr-dt-item">
                                <div className="lbldesc">Airpot Date:</div>
                                <input type="date" className="form-control wide100" ref={travelDate2Ref}  /> 
                            </div>  
                            <div className="form-group fr-dt-item">
                                <div className="lbldesc">Airpot Time:</div>
                                <select className="form-control wide100" ref={travelTime2Ref} >
                                    <option value="Morning Flight">Morning Flight</option>
                                    <option value="Evening Flight">Evening Flight</option>
                                </select>
                            </div>  
                            <div className="form-group fr-dt-item">
                                <div className="lbldesc">Airpot Location Arrival:</div>
                                <input type="text" className="form-control wide100" ref={travelLoc2Ref}  /> 
                            </div>  
                        </div>

                        <div className="flexwidth33">
                            <p className="opt">Option 3</p>
                            <div className="form-group fr-dt-item">
                                <div className="lbldesc">Airpot Date:</div>
                                <input type="date" className="form-control wide100" ref={travelDate3Ref}  /> 
                            </div>  
                            <div className="form-group fr-dt-item">
                                <div className="lbldesc">Airpot Time:</div>
                                <select className="form-control wide100" ref={travelTime3Ref} >
                                    <option value="Morning Flight">Morning Flight</option>
                                    <option value="Evening Flight">Evening Flight</option>
                                </select>
                            </div>  
                            <div className="form-group fr-dt-item">
                                <div className="lbldesc">Airpot Location Arrival:</div>
                                <input type="text" className="form-control wide100" ref={travelLoc3Ref}  /> 
                            </div>  
                        </div>                         
                    </div>                     
                    <div className="form-group">
                          <button type="submit" className="btn btn-rescure" disabled={processing}>Create</button>                             
                    </div>
          </form>
      </div>
      <div className="msg-back mgtop10">
           {
            msgFeedClass && (
              <div className={"alert " + msgFeedClass}>
                {msgFeed}
               </div>
            )
           }
        </div>
        {
            processing && (
                <div className="img-source">
                   <img src={CONSTANTS.ORANGE_PROCESSING} className="proc" />
                </div>
            )
        }
      </div>
    </Dialog>
  )
}

export default DlgCreateFlight