import React, { useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';


function DlgDisplayListClinic({showClinicDialogue, setClinicDialogueList, person}) {
    const [processing, setProcessing]                            = useState(false);

    const [cliniciList, setClinicList]                           = useState([]);


    useEffect(() => {
        collectClinicListings();
    }, [])

    /////////____
    const displaySharingStatus = (rowData) => {     

      var sharingContent = '';
        if(rowData.rsvp.content.sharing_room == 'Yes'){
          const matchedUser = cliniciList.find(user => user.idnumber === rowData.rsvp.content.share_id);
          if(matchedUser){
            sharingContent = matchedUser.name + " " + matchedUser.surname;
          }else {
            sharingContent = 'Yes';
          }
        }else {
          sharingContent = 'Not Sharing';
        }
        return <div className="rsvp sharing">{sharingContent}</div>;
    }
    /////////____

    const collectClinicListings = async () => {
        try{

            const resClinic = await axios.get(CONSTANTS.API_URL +"users/clinic/list/", {
                headers: {
                     token: "Bearer "+ person.accessToken
                 }
              });

              setClinicList(resClinic.data);
        }catch(err){
            console.log(err);
        }
    }

    
  return (
    <Dialog header="Clinic List" visible={showClinicDialogue} onHide={() => setClinicDialogueList(false)} style={{ width: '65vw' }}
        footer={
        <div>      
            <Button className="btn btn-picky" label="Close" onClick={() => setClinicDialogueList(false)} />
        </div>
    }>
      <div className="modal-body-content">
        <div className="listing-container model-in-table">

              {
                  cliniciList && (
                    <DataTable
                      value={cliniciList}
                    
                      className="min-w-full"
                      paginator rows={20} rowsPerPageOptions={[20, 30, 40]}
                      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
                      responsiveLayout="scroll"
                    >
                        <Column field="name" header="Name"></Column>
                        <Column field="surname" header="Surname"></Column>
                        <Column field="email" header="Email"></Column>
                        <Column field="rsvp.content.flight" header="Flight"></Column>  
                        <Column field="rsvp.content.pharmacy_store" header="Store"></Column>  
                        <Column header="Sharing" body={displaySharingStatus}></Column> 
                    </DataTable>
                  )
              }
        </div>
        {
            processing && (
                <div className="img-source">
                   <img src={CONSTANTS.ORANGE_PROCESSING} className="proc" />
                </div>
            )
        }
      </div>
    </Dialog>
  )
}

export default DlgDisplayListClinic