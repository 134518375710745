import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import * as CONSTANTS from "../CONSTANTS";
import { Link } from 'react-router-dom';
import axios from 'axios';
import DlgCreateEvent from '../Components/Dialogs/DlgCreateEvent';

function Events() {

  const {user}                                                = useSelector((state) => state.auth);
  const [eventList, setEventList]                       = useState([]);
  const [eventListArchive, setEventListArchive]         = useState([]);
  const [userList, setUsersList]                            = useState([]);
  const [showEvenCreation, setShowEvenCreate]               = useState();
  const [globalFilter, setGlobalFilter]                       = useState('');
  const [selectedEven, setSelectedEven]                     = useState(null);
  const [evenMade, setEvenMade]                             = useState(0);

  useEffect(() => {
   getAllTheEvents();
  },[]);

  function handleTableFilter(event) {
   
        const newData = eventListArchive.filter(row => {
            const searchText = event.target.value.toLowerCase();
          return ( 
            row.name.toLowerCase().includes(searchText) ||
            row.event_date.toLowerCase().includes(searchText) ||
            row.event_time.toLowerCase().includes(searchText) ||
            row.end_date.toLowerCase().includes(searchText) )
        });
        setEventList(newData);
    }
    //////////___
    const header = (
      <div className="table-header flex justify-content-between">
        <h4 className="mx-0 my-1"></h4>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => handleTableFilter(e.target.value)} placeholder="Search Event..." />
        </span>
      </div>
    );
    //////////___
    //////////___
     const linkEventTemplate = (rowData) => {
      return <Link to={"/event/" + rowData._id} className="btn btn-rescure btsm1">View</Link> 
    }
    //////////___
  const getAllTheEvents = async () => {

    try{
        const res = await axios.get(CONSTANTS.API_URL +"events/ascending");
         console.log(res);
        // console.log("Evenacies");
         console.log(res.data);
         setEventList(res.data);
         setEventListArchive(res.data);

    }catch(erros){
     console.log(erros);     
    }
   }

  const viewAddressTemplate = (rowData) => {     
    return rowData.address.city + " " + rowData.address.zipCode;
  }

  const viewColorTemplate = (rowData) => {
    return <div className="circle-col " style={{backgroundColor:rowData.colorcode}}></div>;
  }

  const viewPublicTemplate = (rowData) => {
    return <div className="pubi ">{rowData.publish ? "Published" : "Drafted"}</div>;
  }

  return (
    <div className="main-standard">
      <h2 className="lbl-title-page">Events</h2>
      <div className="card">
         <div className="card-body">
            <div className="row-box">
               <DlgCreateEvent
                    showEvenCreation={showEvenCreation} 
                    setShowEvenCreate={setShowEvenCreate} 
                    userList={userList}
                    evenMade={evenMade}
                    setEvenMade={setEvenMade}
                    person={user} 
                  />

                 <Button
                      label="Create Event"
                      className="btn btn-rescure btsm1 mr-2"
                      onClick={() => setShowEvenCreate(true)}
                    />
            </div>
            <div className="row-data">
               {
                  eventList && (
                    <DataTable
                        value={eventList}
                        header={header}
                        className="min-w-full"
                        paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} event"
                        responsiveLayout="scroll"
                        globalFilter={(globalFilter) ? globalFilter : ''}
                        selection={selectedEven}
                        onSelectionChange={e => setSelectedEven(e.value)}
                      >
                      
                        <Column field="name" header="Title"></Column>
                        <Column field="event_date" header="Event Date"></Column>
                        <Column field="event_time" header="Time"></Column>
                        <Column field="end_date" header="End Date"></Column>
                        <Column header="Color" body={viewColorTemplate}></Column>  
                        <Column header="Status" body={viewPublicTemplate}></Column>     
                        <Column header="Address" body={viewAddressTemplate}></Column>                            
                        <Column header="View" body={linkEventTemplate}></Column>                     
                    </DataTable>
                  )
              }
            </div>
         </div>
       </div>
    </div>
  )
}

export default Events