import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import processingWinner from '../../assets/comphourg.gif';
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { toast } from 'react-toastify';

function DlgCreateCompCheckEvent({showCompetitionStatusEvent, setShowCompetitionStatusEvent, totalCompeteStat, competitionStatusCheck, currentCompetitionId, person, setAddedCompetition}) {

    const [processing, setProcessing]                       = useState(false);
    const [winnerSelection, setWinningSelection]            = useState();
    
    const runHandleCompetition = async () => {
        setProcessing(true);
        try {
           
    
            const randomIndex = Math.floor(Math.random() * competitionStatusCheck.length);

            const winOb = competitionStatusCheck[randomIndex];
            
            const fetchW = {
                "competitionId" : currentCompetitionId,
                "winnerPhone": winOb._id
            }
            const userWinner = await axios.put(CONSTANTS.API_URL + "events/update/winner/" , fetchW, {
                headers: {
                    token: "Bearer "+ person.accessToken
                }
            });

            setWinningSelection(userWinner.data);
            setTimeout(function() {
                setProcessing(false);
                setAddedCompetition(prevAdd => prevAdd + 1);
            }, 5000);
            
        }catch(err){
            console.log(err);
            setProcessing(false);
        }

    }
  return (
    <Dialog header="Event Competition Prizes" visible={showCompetitionStatusEvent} onHide={() => setShowCompetitionStatusEvent(false)} style={{ width: '50vw' }}
        footer={
            <div>      
                <Button className="btn btn-picky" label="Close" onClick={() => setShowCompetitionStatusEvent(false)} />
            </div>
        }>
        <div className="modal-body-content">
            <div className="form-item form-Even">  
              {
                totalCompeteStat && (
                   <div className="candi-work">
                     <h4>Total candidates for the prize: <strong>{totalCompeteStat}</strong></h4>
                     {
                        processing && (
                            <img src={processingWinner} className="prize-processing" />
                        )
                         
                     } 
                     <div className="">
                       

                        {
                             winnerSelection && (processing === false) ?
                            <div className="congrats-pop-window">
                                Congratulations {winnerSelection.winner.full_name} - {winnerSelection.winner.phonenumber} 
                            </div>
                            :
                            <div className="">
                                 <button className="btn btn-rescure" onClick={runHandleCompetition} disabled={processing}>
                                    RUN
                                </button>
                            </div> 
                        }
                     </div>
                   </div>
                )
              }

            </div>      
        </div>
    </Dialog>
  )
}

export default DlgCreateCompCheckEvent