import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import * as CONSTANTS from "../CONSTANTS";
import axios from 'axios';
import {useParams} from "react-router-dom";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

function SuppliersDetails() {
    const {user}                                       = useSelector((state) => state.auth);
    const [processing, setProcessing]                               = useState(false);
    const [currentSupplier, setCurrentSupplier]                     = useState();
    const [nameChange, setSponsorChange]                            = useState(false);
    const params                                                    = useParams();
    const [updateVar, setUpdateVar]                                 = useState(0);

    const nameUpdateRef                                   = useRef();
    const picnickIdRef                                    = useRef();

    useEffect(() => {
        getCurrentSupplier();   
    },[updateVar])
          
  const getCurrentSupplier = async () => {

    try {
      
        const res = await axios.get(CONSTANTS.API_URL +"suppliers/single/"+ params.id);
        setCurrentSupplier(res.data);
       
      } catch (err) {
        console.log(err);          
      }      
  }

  async function HandleUpdateName(e) {
    e.preventDefault();  
    setProcessing(true);   

    try{
        const userContent = {
            "name" : nameUpdateRef.current.value,
            "supplierid" : currentSupplier._id
           }

           const responseReg = await axios.put(CONSTANTS.API_URL+"suppliers/name/update", userContent, {
                headers: {
                    token: "Bearer "+ user.accessToken
                }
            });

            setUpdateVar(prevAdd => prevAdd + 1);
           setProcessing(false);
           e.target.reset();
           toast.success("You successfully Name.")
    } catch (err){
        console.log(err);
        toast.error("Something went wrong, please try again later.")
      console.log(err);
      setProcessing(false);   
    }       
  }

  const HandlePicknickID = async (e) => {
    e.preventDefault();
    try{

        console.log(currentSupplier);
        
        const picknickUpdater = {
            "supplierId" : currentSupplier._id,
            "picnickId" : picnickIdRef.current.value
        }

        const responseId = await axios.put(CONSTANTS.API_URL+"suppliers/picnick/id/update", picknickUpdater, {
            headers: {
                token: "Bearer "+ user.accessToken
            }
        });

        console.log(responseId);
        if(responseId.status == 200){
            toast.success("Supplier picnick id added.");
        }
    }catch(err){
        console.log(err);
        toast.error("Something went wrong, try again later please.");
    }
  }
  return (
    <div className="main-standard">
        <h2 className="lbl-title-page">Suppliers Detail</h2>
        <div className="card">
            <div className="card-body">
                <div className="section-blocks">
                    <div className="row rs-box">
                        <div className="col-md-5">
                            <div className="item-details">
                                {
                                    currentSupplier && (
                                        <h4>{currentSupplier.supcode}</h4>
                                    )
                                }
                                  <div className="ctx">
                                  Name:
                                    {
                                        currentSupplier && (
                                            <h4 className="titlex1">{currentSupplier.name}</h4>
                                        )
                                    }
                                  </div>
                                  <div className="ctx">                                 
                                    {
                                        currentSupplier && (
                                            <img className="imag-boxer" src={currentSupplier.logo_image}/>
                                        )
                                    }
                                  </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="item-details">
                                <Button
                                    label="Update Name "
                                    className="btn btn-rescure btsm1 mr-2"
                                    onClick={() => setSponsorChange(!nameChange)}
                                />
                                {
                                    nameChange && (
                                        <div className="upate-item">
                                            <form onSubmit={HandleUpdateName}>
                                                <div className="form-group">
                                                        <div className="lbldesc">Name Update*:</div>
                                                        <input type="text" className="form-control wide100" ref={nameUpdateRef}  required/>
                                                    </div>  
                                                    
                                                    <div className="form-group">
                                                        <button type="submit" className="btn btn-rescure" disabled={processing}>Update</button>                             
                                                    </div>
                                             </form>
                                        </div>
                                    )
                                }
                            </div>
                            <div className="item-details mgtop30">
                                <h4>Picnick</h4> 
                                <form onSubmit={HandlePicknickID}>   
                                    <div className="form-group">
                                            <div className="lbldesc">Picnick ID*:</div>
                                            <input type="text" className="form-control wide100" ref={picnickIdRef} defaultValue={currentSupplier?.picnicId} required/>
                                        </div> 
                                       
                                        <div className="form-bt-row">
                                            <button type="submit" className="btn btn-rescure" disabled={processing}>Update</button>                             
                                        </div>
                                    </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SuppliersDetails