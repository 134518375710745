import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import * as CONSTANTS from "../CONSTANTS";
import axios from 'axios';
import {useParams} from "react-router-dom";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import DlgListUsers from '../Components/Dialogs/DlgListUsers';
import DlgUpdateFeaturedImage from '../Components/Dialogs/DlgUpdateFeaturedImage';
import DlgCreateCompetition from '../Components/Dialogs/DlgCreateCompetition';
import DlgCreateAgenda from '../Components/Dialogs/DlgCreateAgenda';
import DlgEditAgenda from '../Components/Dialogs/DlgEditAgenda';
import DlgCreateBanner from '../Components/Dialogs/DlgCreateBanner';
import DlgCreateTradeShow from '../Components/Dialogs/DlgCreateTradeShow';
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import { faExchange } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify';
import DlgCreateCompCheckEvent from '../Components/Dialogs/DlgCreateCompCheckEvent';
import DlgCreateCompCheckSupply from '../Components/Dialogs/DlgCreateCompCheckSupply';
import DlgChartBar from '../Components/Dialogs/DlgChartBar';

function Events() {

  const {user}                                                    = useSelector((state) => state.auth);
  
  const [processing, setProcessing]                               = useState(false);
  const [dislayOrEdit, setDislayOrEdit]                           = useState("showcontent"); //showediting
  const [currentEvent, setCurrentEvent]                           = useState();
  const [selectedEditAgenda, setSelectedEditAgenda]               = useState();
  

  const [agendasList, setAgendaList]                                 = useState([]);

  const [currentSupplierList, setCurrentSupplierList]                = useState([]);
  const [fullSupplierList, setFullSupplierList]                      = useState([]);
  const [supplierAssigning, setSupplierAssigning]                    = useState(0);
  const [addedCompetition, setAddedCompetition]                      = useState(0);
  const [bannerList, setBannerList]                                  = useState([]);
  const [TradeShowList, setTradeShowList]                            = useState([]);
  const [competitionList, setCompetitionList]                        = useState([]);

  const [showUpdateFeaturedImage, setShowUpdateFeaturedImage]        = useState(false);
  
  const [showAgenda, setShowAgendaCreation]                             = useState(false);
  const [showEditeAgendaEventItem, setShowEditeAgendaEventItem]         = useState(false);
  const [showBannerCreation, setShowBannerCreate]                       = useState(false);
  const [showTradeShowCreation, setShowTradeShowCreation]               = useState(false);
  const [showCreateCompetition, setShowCreateCompetition]               = useState(false);
  const [showCompetitionStatusEvent, setShowCompetitionStatusEvent]     = useState(false);
  const [showCompetitionStatusSupply, setShowCompetitionStatusSupply]   = useState(false);
  const [showBarChartDisp, setShowBarChartDisplay]                      = useState(false);


  const [totalCompeteStat, setTotalCompeteStat]                         = useState(0);
  const [totalCompeteSupp, setTotalCompeteSSupp]                        = useState(0);
  const [currentCompetitionId, setCurrentCompetitionId]           = useState("");
  const [competitionStatusCheck, setCompetitionStatusCheck]            = useState();
  const [competitionUserScans, setCompetitionUserScans]            = useState([]);
  const [featuredImageCount, setFeaturedImageCount]                  = useState(0);

  const params                                                       = useParams();

  const [groupedAgendas,setGroupedAgendas]                           = useState({});

  const nameRef                                = useRef();
  const streetRef                              = useRef();  
  const townRef                                = useRef();
  const cityRef                                = useRef();  
  const zipCodeRef                             = useRef();
  const eventDateRef                           = useRef();
  const eventTimeRef                           = useRef();
  const eventEndDateRef                        = useRef();

  useEffect(() => {
    collectEventDetails();  
   // collectSpeakers();   
    collectFullSupplier();      
  },[featuredImageCount])


  useEffect(() => {
    
    if((currentEvent != null) && (currentEvent != undefined)){
        collectTheAgendas();
        collectEventBanners();   
        collectEventTradeShow();  
        setSupplierAssigning(prevSupplierAssigning => prevSupplierAssigning + 1);
    }   
  },[currentEvent])
 
  useEffect(() => {
    if(currentEvent){    
      collectCurrentSuppliers(); 
      collectAllTheCompetitions();
    }
    
  },[supplierAssigning, addedCompetition])

  const collectEventDetails = async () => {

      try {
        
          const res = await axios.get(CONSTANTS.API_URL +"events/single/"+ params.id);
          //console.log(res.data);
          setCurrentEvent(res.data);
         // setGetTheEvents(true);
        } catch (err) {
          console.log(err);          
        }      
  }

  
  const collectFullSupplier = async () => {

    try {
      
        const res = await axios.get(CONSTANTS.API_URL +"suppliers/assembly");
        setFullSupplierList(res.data);
    
      } catch (err) {
        console.log(err);        
      }    
  }

  const collectCurrentSuppliers = async () => {

    try{

      const eveItm = {
        eventCode : currentEvent.event_code
      }
      const res = await axios.put(CONSTANTS.API_URL +"suppliers/getsupevents", eveItm,  {
            headers: {
                token: "Bearer "+ user.accessToken
            }
        });
      //console.log("Current Supplier");
      //console.log(res.data);
      setCurrentSupplierList(res.data);

    }catch (err) {
        console.log(err);        
    }   
  }


  const collectEventBanners = async () => {

    try {
      
        const res = await axios.get(CONSTANTS.API_URL +"events/banners/list/" + currentEvent.event_code);
        //console.log(res.data);
        setBannerList(res.data);
      } catch (err) {
        console.log(err);        
      }    
  }

  const collectEventTradeShow = async () => {

    try {
      
        const res = await axios.get(CONSTANTS.API_URL +"events/tradeshows/list/" + currentEvent.event_code);
        //console.log(res.data);
        setTradeShowList(res.data);
      } catch (err) {
        console.log(err);        
      }    
  }

  const collectTheAgendas = async () => {
    try {
      
          const res = await axios.get(CONSTANTS.API_URL +"events/agenda/list/" + currentEvent.event_code);
          
          //console.log("Edible paddy holder");
          setAgendaList(res.data);
        
        // Group the agendas by agenda_number
        const grouped = {};
        res.data.forEach((agenda) => {
          const agendaNumber = agenda.agenda_number;
          if (!grouped[agendaNumber]) {
            grouped[agendaNumber] = [];
          }
          grouped[agendaNumber].push(agenda);
        });
        setGroupedAgendas(grouped);
      // setGetTheEvents(true);
    } catch (err) {
          console.log(err);            
    }
  }

  const collectAllTheCompetitions = async () => {

    try{
      
      const res = await axios.get(CONSTANTS.API_URL +"events/collect/competitions/" + currentEvent.event_code);
    
      setCompetitionList(res.data);
      //console.log(res.data);
    }catch (err) {
        console.log(err);        
    }   
  }
  async function HandleEditingEvent(e) {
    e.preventDefault();  
    setProcessing(true);   

    try{
    
        const smallEdit = {
          "name" : nameRef.current.value,
          "street" : streetRef.current.value,
          "town" : townRef.current.value,
          "city" : cityRef.current.value,
          "zipCode" : zipCodeRef.current.value,
          "event_date": eventDateRef.current.value,
          "event_time": eventTimeRef.current.value,
          "end_date": eventEndDateRef.current.value,
          "eventcode" : currentEvent.event_code,
        }

        const response = await axios.put(CONSTANTS.API_URL + "events/update/small/fields", smallEdit, {
              headers: {
                  token: "Bearer "+ user.accessToken
              }
          });
        
          toast.success("Successfully updated the event.");
        setProcessing(false);
    } catch (err){
      console.log(err);
      toast.error("Something went wrong.");
      setProcessing(false);   
    }       
  }

  const saveEditNow = () => {
    //dislayOrEdit, 
    //setDislayOrEdit(!dislayOrEdit)
      if(dislayOrEdit.length > 0){
        setDislayOrEdit("")
      }else {
        setDislayOrEdit("showcontent");
      }
  }

  const editThisAgenda = (agendaItem) => {
    //console.log(agendaItem);
    setSelectedEditAgenda(agendaItem);
    setShowEditeAgendaEventItem(agendaItem);
  }

  const switchTheSupplier = async (supItem) => {

    try{

      setProcessing(true);
      const sponsorItem = {
         "supplierid" : supItem._id,
         "eventcode" : currentEvent.event_code
      }
  
      const resSup = await axios.put(CONSTANTS.API_URL + "suppliers/updatesupevent/", sponsorItem, {
            headers: {
                token: "Bearer "+ user.accessToken
            }
        });

        
        setSupplierAssigning(prevSupplierAssigning => prevSupplierAssigning + 1);
        setProcessing(false);
    }catch(err){
      console.log(err);
      setProcessing(false);
    }
  }

  const removeTheSupplier = async (supItem) => {
    try{

      setProcessing(true);
      const sponsorItem = {
         "supplierid" : supItem._id,
         "eventCode" : currentEvent.event_code
      }

      const resSup = await axios.put(CONSTANTS.API_URL + "suppliers/remove/evecode/", sponsorItem, {
            headers: {
                token: "Bearer "+ user.accessToken
            }
        });

        setSupplierAssigning(prevSupplierAssigning => prevSupplierAssigning - 1);
        setProcessing(false);
    }catch(err){
      console.log(err);
      setProcessing(false);
    }
  }

  const publishCurrentEvent = async (status) => {
    try{
      setProcessing(true);
      const dataItem = {
          "eventcode" : currentEvent.event_code,
          "status" : status
        }

      const resEvents = await axios.put(CONSTANTS.API_URL + "events/publish/status/change/", dataItem, {
          headers: {
              token: "Bearer "+ user.accessToken
          }
      });

      if(resEvents.data.publish){
        toast.success("Event has been Published. ")
      }else {
        toast.success("Event has been Drafted. ")
      }
      
      setCurrentEvent(resEvents.data);

      setProcessing(false);
    }catch(err){
      console.log(err);
      toast.error("Something went wrong updating the publish status, please try again later.")
      setProcessing(false);
    }
  }

  const checkEventStatusPop = async (compete) => {
    console.log(compete);
    try{
      setProcessing(true);
      if(compete.comp_type === "Event"){
        //Event Competition Check

        const dataItem = {
          "event_code": compete.eventid          
        }
      
        const resStatus = await axios.put(CONSTANTS.API_URL + "events/check/eventtype/status", dataItem, {
              headers: {
                  token: "Bearer "+ user.accessToken
              }
          });
         
          setCurrentCompetitionId(compete._id);
          setTotalCompeteStat(resStatus.data.total);
          console.log(resStatus.data.content);
          setCompetitionStatusCheck(resStatus.data.content);
          setShowCompetitionStatusEvent(true);
          console.log("status");
          console.log(resStatus.data.total);
      }else {
        //Event Supplier Competition Check
        const dataItem = {
          "event_code": compete.eventid ,
          "supplierid" : compete.comp_supplierid         
        }
        console.log(dataItem);
        
        const supStatus = await axios.put(CONSTANTS.API_URL + "events/check/suppliertype/status", dataItem, {
              headers: {
                  token: "Bearer "+ user.accessToken
              }
          });

          console.log(supStatus.data);

         
          setCurrentCompetitionId(compete._id);
          setTotalCompeteSSupp(supStatus.data.userScans.length);
          console.log(supStatus.data.userScans);
          setCompetitionUserScans(supStatus.data.userScans); 
          setShowCompetitionStatusSupply(true);
      }
      setProcessing(false);
    }catch(err){
      console.log(err);
      setProcessing(false);
    }
  }

  return (
    <div className="main-standard">
      <h2 className="lbl-title-page">Event Detail</h2>
      <div className="card">
        <div className="card-body">
            <div className="section-blocks">
         
                <div className={" form-display " + dislayOrEdit}>
                  <h4 className="lbl-title-inner">Event</h4>
                  <div className="form-content event-srs">
                    {
                      currentEvent && (
                          <div className="event-data">
                             <div style={{backgroundImage: `url(${currentEvent.img_url})`}} className="banner-image" >
                                <Button className="btn btn-rescure bt-img-items" label="Update Image" onClick={() => setShowUpdateFeaturedImage(true)} />
                             </div>
                             <div className="data-cont mgtop30">
                                <div className="publish-item">
                                    {
                                      currentEvent.publish ?
                                      <div className="box-publish">
                                        <div className="publish-status greenify-event">Event is Published</div>
                                      <Button className="btn btn-rescure graygray-out" label="Unpublish" onClick={() => publishCurrentEvent(false)} />
                                      </div>
                                      :
                                        <div className="box-publish">
                                           <div className="publish-status redify-event">Event is Drafted</div>
                                           <Button className="btn btn-rescure" label="Publish" onClick={() => publishCurrentEvent(true)} />
                                        </div>
                                    }
                                </div>

                              <form onSubmit={HandleEditingEvent}>                   
                                        <div className="form-group">
                                              <div className="lbldesc">Title:</div>
                                              <input type="text" className="form-control wide100" ref={nameRef} defaultValue={currentEvent.name} required/>
                                        </div>                      
                                        <div className="form-group">
                                              <div className="lbldesc">Street:</div>
                                              <input type="text" className="form-control wide100" ref={streetRef} defaultValue={currentEvent.address.street} required/>
                                        </div>                    
                                  
                                        <div className="form-group">
                                              <div className="lbldesc">Town:</div>
                                              <input type="text" className="form-control wide100" ref={townRef} defaultValue={currentEvent.address.town} required/>
                                        </div>  
                                        <div className="form-group">
                                              <div className="lbldesc">City:</div>
                                              <input type="text" className="form-control wide100" ref={cityRef} defaultValue={currentEvent.address.city} required/>
                                        </div>
                                        <div className="form-group">
                                              <div className="lbldesc">ZipCode:</div>
                                              <input type="text" className="form-control wide100" ref={zipCodeRef} defaultValue={currentEvent.address.zipCode} />                                
                                        </div>
                                        <div className="flexme">
                                            <div className="form-group flexwidth33">
                                                  <div className="lbldesc">Event Date:</div>
                                                  <input type="text" className="form-control wide100" ref={eventDateRef} defaultValue={currentEvent.event_date} />
                                            </div>
                                            <div className="form-group flexwidth33">
                                                  <div className="lbldesc">Event Time:</div>
                                                  <input type="text" className="form-control wide100" ref={eventTimeRef} defaultValue={currentEvent.event_time} />
                                            </div>
                                            <div className="form-group flexwidth33">
                                                  <div className="lbldesc">Event End Date:</div>
                                                  <input type="text" className="form-control wide100" ref={eventEndDateRef} defaultValue={currentEvent.end_date} />
                                            </div>
                                        </div>
                                      
                                        {
                                          (dislayOrEdit == "") && (
                                            <div className="form-group">
                                                <button type="submit" className="btn btn-rescure" disabled={processing}>Safe Edit</button>                             
                                          </div>
                                          )
                                        }
                                    
                              </form>
                              </div>
                          
                          <span onClick={() => saveEditNow()} className="txtTab">Edit</span>
                          </div>
                          )
                    }
                
                  </div>
         
                </div>

              
            </div>
        
            <div className={"section-blocks"}>
               <h4 className="lbl-title-inner">Agendas</h4>
               <div className="data-cont">
                {
                  currentEvent && (
                      <DlgEditAgenda
                          setShowEditeAgendaEventItem={setShowEditeAgendaEventItem} 
                          showEditeAgendaEventItem={showEditeAgendaEventItem} 
                          selectedAgenda={selectedEditAgenda}
                          currentEvent={currentEvent}
                          person={user} 
                        />
                    )
                }
           

                  <DlgCreateAgenda
                          setShowAgendaCreation={setShowAgendaCreation} 
                          showAgenda={showAgenda} 
                          currentEvent={currentEvent}
                          person={user} 
                        />
    
                      <Button
                            label="Create Agenda"
                            className="btn btn-rescure btsm1 mr-2"
                            onClick={() => setShowAgendaCreation(true)}
                          />
                          <div className="cont-agenda-slow pd10"></div>
                  </div>
                  <div className="mgtop10 cont-strip row agenda-list">
                          {
                            groupedAgendas && (
                              <>
                                {Object.entries(groupedAgendas).map(([agendaNumber, agendas]) => (
                                    <div key={agendaNumber} className="col-md-3 ">
                                       <div className="scroll-phase-list">
                                       <h3>Day {agendaNumber}</h3>
                                      <ul className="no-style ">
                                        {agendas.map((agenda) => (
                                          <li key={agenda._id} onClick={() => editThisAgenda(agenda)}>
                                            <div className="flexme ms-item-block">
                                              <div className="flexwidth75">
                                                <div className="title-top">
                                                  {agenda.title}
                                                </div>
                                                <div className="time-top">
                                                  {agenda.start_time} - {agenda.end_time}
                                                </div>
                                              </div>
                                              <div className="flexwidth33">
                                                  {
                                                    (agenda.speakers.length > 0) && (
                                                      <div className="contact-inky" >
                                                          {
                                                            agenda.speakers.map((speak, i) => {
                                                             return (<div className="sp-work" key={i}>
                                                                <div className="img-speak-v" style={{backgroundImage: `url(${speak.img_url})`}}></div>
                                                                <div className="txt-speak-tit">{speak.name} </div><div className="txt-speak-tit"> {speak.surname}</div>
                                                              </div>)
                                                            }) 
                                                          }
                                                      </div>
                                                    )
                                                  }
                                              </div>
                                            </div>
                                          </li>
                                        ))}
                                      </ul>
                                       </div>
                                    </div>
                                  ))
                                }
                              </>
                            )
                          }
                  </div>
               
            </div>
            <div className="section-blocks">
               <h4 className="lbl-title-inner">Supplier/Sponsers</h4>
               <div className="data-cont">
                  <div className="flexme">
                      <div className="flexwidth50">
                         <h4 className="gray-t"> Event Sponsers/Suppliers</h4>
                         <div className="supplier-strip current-supps">
                            {
                              currentSupplierList && (
                                <ul className="no-style">
                                  {
                                    currentSupplierList.map((supplier, index) => {
                                      return (<li key={index} className="pharm-item supli">
                                          <div className="logo-ev-cont">
                                            <img src={supplier.logo_image} className="event-image-logo" />
                                          </div>
                                          <div className="item-text contsup">
                                            <div className="head-sponsor">{supplier.name}</div>
                                            <strong>{supplier.supcode}</strong>
                                          </div>
                                          <button type="button" className="btn btn-close-ls trback posi-close " onClick={() => removeTheSupplier(supplier)}>
                                             <span className="supplier-icon"><FontAwesomeIcon icon={faExchange} /></span> 
                                          </button>
                                      </li>)
                                    })
                                  }
                                </ul>
                              )
                            }
                          </div>
                      </div>
                      <div className="flexwidth50">
                          <h4 className="gray-t">Sponsers/Suppliers List</h4>
                          <div className="supplier-strip">
                            {
                              fullSupplierList && (
                                <ul className="no-style">
                                  {
                                    fullSupplierList.map((supplier, index) => {
                                      return (<li key={index} className="pharm-item">
                                          <div className="logo-ev-cont">
                                            <img src={supplier.logo_image} className="event-image-logo" />
                                          </div>
                                          <div className="item-text contsup">
                                            <div className="head-sponsor">{supplier.name}</div>
                                            <strong>{supplier.supcode}</strong>
                                          </div>
                                          <button type="button" className="btn btn-close-ls posi-close " onClick={() => switchTheSupplier(supplier)}>
                                             <span className="supplier-icon"><FontAwesomeIcon icon={faExchange} /></span> 
                                          </button>
                                      </li>)
                                    })
                                  }
                                </ul>
                              )
                            }
                          </div>
                      </div>
                  </div>
               </div>
            </div>
            <div className="section-blocks">
               <h4 className="lbl-title-inner">Banner</h4>
               <div className="data-cont">
                    <DlgCreateBanner
                        showBannerCreation={showBannerCreation}
                        setShowBannerCreate={setShowBannerCreate}
                        currentEvent={currentEvent}
                        person={user} 
                    />

                    <Button
                        label="Create Banner"
                        className="btn btn-rescure btsm1 mr-2"
                        onClick={() => setShowBannerCreate(true)}
                      />
               </div>
               <div className="mgtop10 cont-strip banner-list">
                        {
                          (bannerList.length > 0) && (
                            <div className="row banner-listing ">
                              {
                                bannerList.map((banner, index) => {
                                  return (<div className="col-md-4" key={index}>
                                    <div style={{backgroundImage: `url(${banner.banner_url})`}} className="banner-sub-slide dsq1" >
                              
                                    </div>
                                  </div>)
                                })
                              }
                            </div>
                          )
                        }
                </div>
            </div>
        
            <div className="section-blocks">
               <h4 className="lbl-title-inner">TradeShow</h4>
               <div className="data-cont">
                 <DlgCreateTradeShow
                   showTradeShowCreation={showTradeShowCreation} 
                   setShowTradeShowCreation={setShowTradeShowCreation}
                   currentEvent={currentEvent}
                   person={user} 
                    />

                    <Button
                        label="Create TradeShow"
                        className="btn btn-rescure btsm1 mr-2"
                        onClick={() => setShowTradeShowCreation(true)}
                      />
               </div>
               <div className="mgtop10 cont-strip tradeshow-list">
                        {
                          (TradeShowList.length > 0) && (
                            <div className="row tradeshow-listing ">
                              {
                                TradeShowList.map((trade, index) => {
                                  return (<div className="col-md-2" key={index}>
                                    <Link to={trade.pdf_url} target='_blank'>{trade.name}</Link>
                                  </div>)
                                })
                              }
                            </div>
                          )
                        }
                </div>
            </div>

            <div className="section-blocks">
               <h4 className="lbl-title-inner">Competions</h4>
               <div className="data-cont">
                <DlgCreateCompetition
                    showCreateCompetition={showCreateCompetition} 
                    setShowCreateCompetition={setShowCreateCompetition}
                    currentEvent={currentEvent}
                    person={user} 
                    currentSupplierList={currentSupplierList}
                    />

                <Button
                      label="Create Competition"
                      className="btn btn-rescure btsm1 mr-2"
                      onClick={() => setShowCreateCompetition(true)}
                    />
                
                <DlgCreateCompCheckEvent
                  showCompetitionStatusEvent={showCompetitionStatusEvent}
                  setShowCompetitionStatusEvent={setShowCompetitionStatusEvent}
                  totalCompeteStat={totalCompeteStat}
                  competitionStatusCheck={competitionStatusCheck}
                  currentCompetitionId={currentCompetitionId}
                  person={user}
                  setAddedCompetition={setAddedCompetition}
                  />

                <DlgCreateCompCheckSupply
                    showCompetitionStatusSupply={showCompetitionStatusSupply}
                    setShowCompetitionStatusSupply={setShowCompetitionStatusSupply}
                    totalCompeteSupp={totalCompeteSupp}
                    competitionUserScans={competitionUserScans}
                    currentCompetitionId={currentCompetitionId}
                    person={user}
                    setAddedCompetition={setAddedCompetition}
                  />
               </div>
               <div className="mgtop10 mccompetition">
                   {
                      competitionList && (
                        <table className="table">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Name</th>
                              <th>Event Type</th>
                              <th colSpan={2}>Win</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              competitionList.map((compete, index) => {
                                return <tr key={index}>                                  
                                  <td><img src={compete.prize_url} className="img-comp" /></td>
                                  <td>{compete.name}</td>
                                  <td>{compete.comp_type}</td>
                                  <td>{
                                    (compete.winner.status == false) &&
                                       <div className="win-pop">
                                        Pending Winner
                                       </div>
                                    }</td>
                                  <td>{
                                    (compete.winner.status == false) ? 
                                       <div className="run-comp">
                                        <button className="btn btn-rescure" onClick={() => checkEventStatusPop(compete)}>RUN COMPETITION</button>
                                       </div>
                                      :
                                      <div className="winning-box">
                                        {compete.winner.phonenumber} -  {compete.winner.full_name} 
                                      </div>
                                    }</td>
                                </tr>
                              })
                            }
                          </tbody>
                        </table>
                      )
                   }
               </div>
            </div>

            <div className="section-blocks">
              <h4 className="lbl-title-inner">Reports</h4>
              <div className="data-cont">
                 <div className="col-md-4">
                    <div className="box-rep">
                    <h5>Total Supplier Scans</h5>

                        <DlgChartBar
                          showBarChartDisp={showBarChartDisp}
                          setShowBarChartDisplay={setShowBarChartDisplay}
                          currentEvent={currentEvent}
                          person={user}
                        />

                        <Button
                          label="View Chart Bar"
                          className="btn btn-rescure btsm1 mr-2"
                          onClick={() => setShowBarChartDisplay(true)}
                        />

                    </div>

                 </div>
              </div>
            </div>
        </div>
        {
           processing && (
            <div className="position-center">
              <img src={CONSTANTS.ORANGE_PROCESSING} className="proc" />
            </div>
           )
        }
      </div>
    </div>
  )
}

export default Events