import React, { useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { toast } from 'react-toastify';


function DlgCreateSupplier({showSuppliersCreation, setShowSuppliersCreation, setMsgFeedClass, person}) {
    const [processing, setProcessing]                            = useState(false);

    

    const supplierImageToUploadRef                = useRef();
    const supplierNameRef                         = useRef();
    const supplierBioRef                          = useRef();
    const supplierTitleRef                        = useRef();

    function makeid(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
          counter += 1;
        }
        return result;
    }
    
    async function HandleCreatingTheSupplier(e) {
        e.preventDefault();  
        setProcessing(true);   
    
        try{
      
          if(supplierImageToUploadRef.current.files[0]){
            //const codeGenerate = makeid(9);
            const digiTwo = Math.floor(Math.random() * 9000) + 1000;
            const codeGenerate    = Math.floor(Math.random() * 90000) + 10000 + "" + digiTwo;
            const formData = new FormData();
            formData.append('supplierurl',  supplierImageToUploadRef.current.files[0]);
    
              const response = await axios.post(CONSTANTS.UPLOAD_MEVPHONE, formData);    
          

              if(response.status == 200){
                
                const objectEvent = {
                  "logo_image"    : response.data.imageUrl,
                  "name"       : supplierNameRef.current.value,
                  "supcode"    : codeGenerate,
                  "banner_url" : "",
                  "notes"   : supplierBioRef.current.value,         
                }
    
                const content = await axios.post(CONSTANTS.API_URL+"suppliers/add",objectEvent);
    
                //console.log(content.data);
                toast.success("Successully added Supplier.");
                setMsgFeedClass("alert-success");
                e.target.reset();
                
              }else {               
                toast.error("Something went wrong with uploading the image, please try again later.");              
              }
          }
           //setUserFound(false);
           setProcessing(false);   
          // 
        } catch (err){
          console.log(err);
          setProcessing(false);   
        }       
      }

  return (
    <Dialog header="Create Supplier" visible={showSuppliersCreation} onHide={() => setShowSuppliersCreation(false)} style={{ width: '50vw' }}
    footer={
         <div>      
            <Button className="btn btn-picky" label="Close" onClick={() => setShowSuppliersCreation(false)} />
          </div>
        }> 
        <div className="modal-body-content">
           <div className="form-item form-pharm">
           <form onSubmit={HandleCreatingTheSupplier}>   
               <div className="form-group">
                    <div className="lbldesc">Supplier Name*:</div>
                    <input type="text" className="form-control wide100" ref={supplierNameRef} required/>
                </div> 
                <div className="form-group">
                    <div className="lbldesc">Bio:</div>
                    <textarea className="form-control wide100" ref={supplierBioRef}></textarea>
                </div> 
                <div className="form-group">
                        Supplier Logo <small>(2 mb)</small>:
                    <input type="file" ref={supplierImageToUploadRef} multiple="" accept="image/*" required/>
                </div>
                <div className="form-bt-row">
                    <button type="submit" className="btn btn-rescure" disabled={processing}>Create</button>                             
                </div>
            </form>
             
           </div>
         {
            processing && (
                <div className="img-source">
                   <img src={CONSTANTS.ORANGE_PROCESSING} className="proc" />
                </div>
            )
        }
    </div>
     </Dialog>
  )
}

export default DlgCreateSupplier