import React, { useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { toast } from 'react-toastify';


function DlgCreateAgenda({showAgenda, setShowAgendaCreation, currentEvent, person}) {
    const [processing, setProcessing]                            = useState(false);
    const [speakerOption, setSpeakerOption]                      = useState(false);
    const [speakerList, setSpeakersList]                         = useState([]);

    const [agendaType, setAgendaType]                            = useState('normal');
    const [agendaTypeNumber, setAgendaTypeNumber]                = useState('1');
    const [msgFeed, setMsgFeed]                                  = useState('');
    const [msgFeedClass, setMsgFeedClass]                        = useState('');

    const agendaTaskRef                          = useRef();
    const startTimeRef                           = useRef();
    const endTimeRef                             = useRef();
    const speakerSelectedRef                     = useRef("");
    const agendaDateRef                          = useRef();
    const agendaDayRef                           = useRef();

    useEffect(() => {
        collectSpeakers();
    },[speakerOption])

    const collectSpeakers = async () => {
        try {
        
            const res = await axios.get(CONSTANTS.API_URL +"events/speakers/list");
            //console.log(res.data);
            setSpeakersList(res.data);
        // setGetTheEvents(true);
       } catch (err) {
            console.log(err);            
       }
    }


    const handleAgendaTypeChange = (event) => {
        setAgendaType(event.target.value);
        // If speaker is chosen, set the speaker state variable to true
        if (event.target.value === 'speaker') {
            setSpeakerOption(true);
            setAgendaTypeNumber("2")
        } else {
            setSpeakerOption(false);
            setAgendaTypeNumber("1")
        }
    }

    async function HandleCreatingTheAgenda(e) {
        e.preventDefault();  
        setProcessing(true);   
    
        try{
      
            setMsgFeedClass("");

                var objectEvent = {}

                if(speakerOption){
                    objectEvent = {
                        "agenda_type"  : agendaTypeNumber,
                        "task" : agendaTaskRef.current.value,
                        "start" : startTimeRef.current.value,
                        "end" : endTimeRef.current.value,
                        "speakerID"   : speakerSelectedRef.current.value,
                        "eventid"  : currentEvent.event_code, 
                        "event_date"  : agendaDateRef.current.value,    
                        "agenda_number": agendaDayRef.current.value,           
                      }
                }else {
                    objectEvent = {
                        "agenda_type"  : agendaTypeNumber,
                        "task" : agendaTaskRef.current.value,
                        "start" : startTimeRef.current.value,
                        "end" : endTimeRef.current.value,
                        "eventid"  : currentEvent.event_code, 
                        "event_date"  : agendaDateRef.current.value,  
                        "agenda_number": agendaDayRef.current.value,                
                      }
                }
             
            
                const content = await axios.post(CONSTANTS.API_URL+"events/agenda/add",objectEvent, {
                    headers:{
                        "token": "Bearer " + person.accessToken
                    }
                });
                
                //console.log(content.data);
                
                setMsgFeed("Successully added Agenda.");
                setMsgFeedClass("alert-success");
                e.target.reset();
                
           //setUserFound(false);
           setProcessing(false);   
          // 
        } catch (err){
          console.log(err);
          setProcessing(false);   
        }       
    }

  return (
    <Dialog header="Create Agenda" visible={showAgenda} onHide={() => setShowAgendaCreation(false)} style={{ width: '50vw' }}
    footer={
         <div>      
            <Button className="btn btn-picky" label="Close" onClick={() => setShowAgendaCreation(false)} />
          </div>
        }> 
        <div className="modal-body-content">
           <div className="form-item form-pharm">
           <form onSubmit={HandleCreatingTheAgenda}>   
               <div className="form-group">
                    <div className="lbldesc">Agenda*:</div>
                    <input type="text" className="form-control wide100" ref={agendaTaskRef} placeholder="Task..." required/>
                </div> 
                <div className="form-group">
                    <div className="lbldesc">Start Time:</div>
                    <input type="time" className="form-control wide100" ref={startTimeRef} required/>
                </div> 
                <div className="form-group">
                    <div className="lbldesc">End Time*:</div>
                    <input type="time" className="form-control wide100" ref={endTimeRef} required/>
                </div> 
                <div className="form-group">
                    <div className="lbldesc">Date*:</div>
                    <input type="date" className="form-control wide100" ref={agendaDateRef} required/>
                </div> 
                <div className="form-group">
                    <div className="lbldesc">Day:</div>
                    <input type="number" className="form-control wide100" ref={agendaDayRef} min={1} defaultValue="1" required/>
                </div> 
                <div className="form-group agenda-radios">
                    <div className="lbldesc">Agenda Type*:</div>
                        <label>
                            <input type="radio" value="normal" checked={agendaType === 'normal'} onChange={handleAgendaTypeChange} />
                            <span className="lb-ag"> Normal </span>
                        </label>
                        <label>
                            <input type="radio" value="speaker" checked={agendaType === 'speaker'} onChange={handleAgendaTypeChange} />
                            <span className="lb-ag"> Speaker</span>
                        </label>
                      
                </div>
                {
                    speakerOption && (
                        <div className="form-group">
                                Select Speaker
                               <select className="form-control" ref={speakerSelectedRef}>
                               {
                                    speakerList.map((speaker, index) => {
                                        return (<option key={index} value={speaker.subid}>{speaker.name} {speaker.surname}</option>)
                                    })
                                }
                               </select>
                        </div>
                    )
                }
                <div className="form-bt-row">
                    <button type="submit" className="btn btn-rescure" disabled={processing}>Create</button>                             
                </div>
            </form>
             
             <div className="mgtop10">
                {
                    msgFeed && (
                        <div className={"alert " + msgFeedClass  }>
                            {msgFeed}
                        </div>
                    )
                }
             </div>
           </div>
         {
            processing && (
                <div className="img-source">
                   <img src={CONSTANTS.ORANGE_PROCESSING} className="proc" />
                </div>
            )
        }
    </div>
     </Dialog>
  )
}

export default DlgCreateAgenda