import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import './logauth.css'
import * as CONSTANTS from "../CONSTANTS";
import SideImage from './../assets/welcome.gif';
import HeaderLogo from './../assets/tlc_logo.png';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { login, reset } from '../reduxAuth/authSlice';


function Login() {
    const [erEmpty, setErEmpty]                         = useState("");
    const [erMClass, setErrMClass]                      = useState("");
    const emailRef                                      = useRef();
    const passwordRef                                   = useRef();

    const navigate                                       = useNavigate();
    const dispatch                                       = useDispatch();

    const {user, isLoading, isError, isSuccess, message} = useSelector((state) => state.auth);

    useEffect(() => {

        if(isError){
            toast.error(message)
        }
      
        if(isSuccess || user){
            navigate('/');
          }

          dispatch(reset())
    },[user, isError, isSuccess, message, navigate, dispatch])

    const handleLogin = async (e) => {
        e.preventDefault();
 
        let emailCheckTest = isValidEmail(emailRef.current.value);
        if(emailCheckTest){

            const userData = {
                "email": emailRef.current.value,
                "password": passwordRef.current.value
              }        
              dispatch(login(userData));
        }else {
            toast.error("Email not valid");
        }
    }

    function isValidEmail(email){
        return /\S+@\S+\.\S+/.test(email);
    }

  return (
    <div className="logo-base flexlog">
        <div className="log-start">
            <div className="reg-header">
                <img src={HeaderLogo} className="logo-one" alt="Mevent TLC" />
         
            </div>
            <div className="main-login-data">
                <div className="data-image-block">
                        <div className="base-login">
                            <img src={SideImage} className="logo-on-display" alt="Mevent TLC" />
                        </div>
                </div>
                <div className="form-block">
                    <div className="frm-area">
                        <h2 className="title-login">Mevent - The Local Choice - Login</h2>
                            <form encType="multipart/form-data">
                            <div className="form-group frg">
                                <div className="text-form">Email: </div>
                                <input type="email" className="form-control ct-content wide100" ref={emailRef}  placeholder="Enter Email Address" required/>
                            </div>
                            <div className="form-group frg">
                                <div className="text-form">Password: </div>
                                <input type="password" className="form-control ct-content wide100"  ref={passwordRef} placeholder="Enter Password" required/>
                            </div>
                            <div className="form-group frg">
                                <button className="btn btn-rescure-login btn-full" onClick={handleLogin} disabled={isLoading}>Login</button>
                            </div>
                            <div className=" frg">
                                
                            </div>
                            </form>

                            {
                                isLoading && (
                                    <div className="img-source">
                                        <img src={CONSTANTS.ORANGE_PROCESSING} className="proc" />
                                    </div>
                                )
                            }
                            {
                                erEmpty && (
                                    <div className={"alert " + erMClass}>
                                        {erEmpty}
                                    </div>
                                )
                            }
                    </div>
                </div>
            
            </div>
        </div>       
    </div>
  )
}

export default Login