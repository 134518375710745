import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
import DlgEditUser from '../Components/Dialogs/DlgEditUser';

function UserDetails() {
    const {user}                                                                = useSelector((state) => state.auth);
    const [processing, setProcessing]                                           = useState(false);
    const [showEditCurrentBasicUsers, setShowEditCurrentBasicUsers]             = useState(false);
    const [emailReminderMessage, setEmailReminderMessage]                       = useState("");
    const [resetPassword, setResetPassword]                                     = useState("");

    const params                                                          = useParams();
    const [currentUser, setCurrentUser]                                   = useState();
    const [updatePerson, setUpdatePerson]                                 = useState(0);

    useEffect(() => {
        getCurrentUser();  

    },[updatePerson])

    const getCurrentUser = async () => {

        try {
          
            const res = await axios.get(CONSTANTS.API_URL +"users/sole/"+ params.id, {
                headers: {
                     token: "Bearer "+ user.accessToken
                 }
              });
            
            setCurrentUser(res.data);

            if(res.data.rsvp.seen === false){
                const res = await axios.put(CONSTANTS.API_URL +"users/rsvp/seen/"+ params.id);
            }
          } catch (err) {
            console.log(err);          
          }      
      }

      const resetCurrentPassword = async () => {
        setProcessing(true);
        const phoneOb = {
            "phonenumber" : currentUser.phonenumber
        }

        const res = await axios.put(CONSTANTS.API_URL +"auth/passreset/", phoneOb );
        console.log(res);
        if(res.status == 200){
            setResetPassword(res.data.message + " [" + res.data.new_pass +"]");
        }
        setProcessing(false);
      }

      const sendCurrentUserEmail = async () => {

        const objectEmail = {
            "phonenumber" : currentUser.phonenumber,
            "name" : currentUser.name,
            "surname" : currentUser.surname,
            "email" : currentUser.email,
        }

        const res = await axios.put(CONSTANTS.API_URL +"users/rsvp/send/reminder/", objectEmail);
       
        if(res.status === 200){
            setEmailReminderMessage(res.data.Message);
        }
      }
  return (
    <div className="main-standard">
      <h2 className="lbl-title-page">User Detail</h2>
      <div className="card">
        <div className="card-body">
                         
            <DlgEditUser 
                setShowEditCurrentBasicUsers={setShowEditCurrentBasicUsers} 
                showEditCurrentBasicUsers={showEditCurrentBasicUsers} 
                currentUser={currentUser}
                person={user} 
                />
            
            {
                currentUser && (
                    <div className="out-container">
                       <div className="rsvp-container">
                        <p className="">
                          <span className="download-short" onClick={() => sendCurrentUserEmail()}>Clicking here</span> to request for user to upate their RSVP.                  
                        </p>
                        {
                            emailReminderMessage && (
                                <div className="alert alert-success">
                                    {emailReminderMessage}
                                </div>
                            )
                        }
                        {
                            currentUser.rsvp.status == true ? (
                                    <div className="rsvp-box">
                                        <h4>Completed the RSVP</h4>
                                        <div className="row rx-svp">
                                            <div className="col-md-4">
                                               <div className="col-block">
                                                   <div className="tt-rs">Conference Role Type</div>
                                                   <div className="tt-ans">{currentUser.rsvp.content.conference_type}</div>
                                                   <div className="tt-rs">Pharmacy Store</div>
                                                   <div className="tt-ans">{currentUser.rsvp.content.pharmacy_store}</div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                               <div className="col-block">
                                                   <div className="tt-rs">Flight</div>
                                                   <div className="tt-ans">{currentUser.rsvp.content.flight}</div>                                                   
                                                   <div className="tt-rs">Flight Province</div>
                                                   <div className="tt-ans">{currentUser.rsvp.content.flight_province}</div>
                                                   <div className="tt-rs">Flight Date</div>
                                                   <div className="tt-ans">{currentUser.rsvp.content.flight_date}</div>
                                                </div>
                                            </div>
                                            {
                                                currentUser.rsvp.content.conference_type == "Franchisee, RP, Pharmacy Manager" ?
                                                 <>
                                                    <div className="col-md-4">
                                                        <div className="col-block">
                                                            <div className="tt-rs">Golf</div>
                                                            <div className="tt-ans">{currentUser.rsvp.content.golf}</div>
                                                            <div className="tt-rs">Accommodation</div>
                                                            <div className="tt-ans">{currentUser.rsvp.content.upgrade ? "Upgraded Accommodation" : "Standard Accommodation"}</div>
                                                            {
                                                                currentUser.rsvp.content.PCDT.pcdt_pharmacist == "Yes" && (
                                                                    <>
                                                                        <div className="tt-rs">PCDT</div>
                                                                        <div className="tt-ans">Yes</div>
                                                                        <div className="tt-rs">Attending Clinic Event?</div>
                                                                        <div className="tt-ans">{currentUser.rsvp.content.PCDT.pcdt_clinic}</div>
                                                                    </>
                                                                )                                                                    
                                                            }  
                                                        </div>
                                                    </div>
                                                 </>
                                                :
                                                <div className="col-md-4">
                                                    <div className="col-block">
                                                        <div className="tt-rs">Sharing Room</div>
                                                        <div className="tt-ans">{currentUser.rsvp.content.sharing_room}</div>
                                                        <div className="tt-rs">Sharing Person</div>
                                                        <div className="tt-ans">{currentUser.rsvp.content.share_id.substr(0, 7)} ...</div>
                                                    </div>
                                                </div>
                                            }
                                            
                                            <div className="col-md-4">
                                               <div className="col-block">
                                                   <div className="tt-rs">Spouse Coming</div>
                                                   <div className="tt-ans">{currentUser.rsvp.content.spouse.spouse_coming == "true" ? "Yes" : "No"}</div>
                                                   {
                                                        currentUser.rsvp.content.spouse.spouse_coming == "true" &&
                                                        <>
                                                                <div className="tt-rs">Name</div>
                                                                <div className="tt-ans">{currentUser.rsvp.content.spouse.spouse_name}</div>
                                                                <div className="tt-rs">Works for pharmacy</div>
                                                                <div className="tt-ans">{currentUser.rsvp.content.spouse.spouse_pharmacy_worker}</div>
                                                                <div className="tt-rs">Designation</div>
                                                                <div className="tt-ans">{currentUser.rsvp.content.spouse.spouse_designation}</div>
                                                        </>
                                                   }
                                           
                                                </div>
                                            </div>
                                         
                                            <div className="col-md-4">
                                               <div className="col-block">
                                                   <div className="tt-rs">Any Comment</div>
                                                   <div className="tt-ans">{currentUser.rsvp.content.any_comment}</div>                                                   
                                              
                                                </div>
                                            </div>                                       

                                        </div>
                                    
                                    </div>
                              
                            )
                            :
                             <div className="rsvp-box">
                                 <h4>Did not complete the RSVP</h4>
                             </div>
                        }
                       </div>
                       <div className="details-container">
                        <h4>Personal Details</h4>
                          <table className="table">
                            <tbody>
                                <tr>
                                    <td>Name</td>
                                    <td>:</td>
                                    <td>{currentUser.name}</td>
                                </tr>
                                <tr>
                                    <td>Surname</td>
                                    <td>:</td>
                                    <td> {currentUser.surname}</td>
                                </tr>
                                <tr>
                                    <td>Email</td>
                                    <td>:</td>
                                    <td>   
                                       {currentUser.email}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Phone </td>
                                    <td>:</td>
                                    <td> {currentUser.phonenumber}</td>
                                </tr>
                                <tr>
                                    <td>Dietary</td>
                                    <td>:</td>
                                    <td>{currentUser.dietary}</td>
                                </tr>                                
                                <tr>
                                    <td>Profession </td>
                                    <td>:</td>
                                    <td> {currentUser.profession}</td>
                                </tr>
                            </tbody>
                          </table>
                          <div className="flexme">
                             <button className="btn btn-rescure btsm1 mr-2" onClick={() => setShowEditCurrentBasicUsers(true)}>Edit</button>
                             <button className="btn btn-rescure btsm1 mr-2" onClick={() => resetCurrentPassword()}>Reset Password</button>
                          </div>
                          {
                            resetPassword && (
                                <div className="mgtop10">
                                    <div className="alert alert-success">
                                        {resetPassword}
                                    </div>
                                </div>
                            )
                          }
                       </div>
                    </div>
                )
            }
        </div>
      </div>
    </div>
  )
}

export default UserDetails