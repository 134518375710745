import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyA06ZMeyLko4uNOnL8P8HOkYPycIC0Dpf4",
    authDomain: "meventphonetlc.firebaseapp.com",
    projectId: "meventphonetlc",
    storageBucket: "meventphonetlc.appspot.com",
    messagingSenderId: "842361716779",
    appId: "1:842361716779:web:101bf5d0584202e310aa6e",
    measurementId: "G-FRMZZ853TM"
  };
  
// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Get Firestore instance
const firestore = getFirestore(firebaseApp);


export { firestore , collection, getDocs};

