import React, { useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { toast } from 'react-toastify';

function DlgEditUser({showEditCurrentBasicUsers, setShowEditCurrentBasicUsers, currentUser, person}) {
    const [processing, setProcessing]                            = useState(false);

    const nameRef                                    = useRef();
    const surnameRef                                 = useRef();
    const emailRef                                   = useRef();
    const phonenumberRef                             = useRef();
    const dietaryRef                                 = useRef();
    const professionRef                              = useRef();

    async function HandleUpdatingUserDetails(e) {
        e.preventDefault();  
        setProcessing(true);   
    
        try{

            const userContent = {
                "userid" : currentUser._id,
                "dataobject" : {
                    "name" : nameRef.current.value,
                    "surname" : surnameRef.current.value,
                    "email": emailRef.current.value,
                    "phonenumber" : phonenumberRef.current.value,
                    "dietary" : dietaryRef.current.value,
                    "profession": professionRef.current.value
                }
            }

        const responseReg = await axios.put(CONSTANTS.API_URL+"users/basic/details", userContent, {
                headers: {
                    token: "Bearer "+ person.accessToken
                }
            });

            //console.log(responseReg);
            //console.log(responseReg.data);
            
            toast.success("You successfully Edit this user.");
            setProcessing(false);

        }catch(err){
            console.log(err);
        }
    }

  return (
    <Dialog header="Edit Basic Details" visible={showEditCurrentBasicUsers} onHide={() => setShowEditCurrentBasicUsers(false)} style={{ width: '50vw' }}
        footer={
            <div>      
                <Button className="btn btn-picky" label="Close" onClick={() => setShowEditCurrentBasicUsers(false)} />
            </div>
        }>
      <div className="modal-body-content">
        <div className="data-item list-pharm-users">
            <div className="data-item list-flight">
                {
                    currentUser && (
                        <form onSubmit={HandleUpdatingUserDetails}>
                                    
                                <div className="form-group">
                                    <div className="lbldesc">Name*:</div>
                                    <input type="text" className="form-control wide100" ref={nameRef} defaultValue={currentUser.name} required/>
                                </div>     
                                <div className="form-group">
                                    <div className="lbldesc">Surname*:</div>
                                    <input type="text" className="form-control wide100" ref={surnameRef} defaultValue={currentUser.surname} required/>
                                </div>  
                                <div className="form-group">
                                    <div className="lbldesc">Email*:</div>
                                    <input type="text" className="form-control wide100" ref={emailRef}  defaultValue={currentUser.email} required/>                         
                                </div>   
                                <div className="form-group">
                                    <div className="lbldesc">Dietary*:</div>
                                    <select  className="form-control wide100" ref={dietaryRef} defaultValue={currentUser.dietary}>
                                        <option value="None">None</option>
                                        <option value="Vegetarian">Vegetarian</option>
                                        <option value="Kosher">Kosher</option>
                                        <option value="Halaal">Halaal</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <div className="lbldesc">Phone Number*:</div>
                                    <input type="text" className="form-control wide100" ref={phonenumberRef}  defaultValue={currentUser.phonenumber} required/>                         
                                </div>  
                                <div className="form-group">
                                    <div className="lbldesc">Profession:</div>
                                    <input type="text" className="form-control wide100" ref={professionRef}  defaultValue={currentUser.profession} />                         
                                </div>  
                                
                                <div className="form-group">
                                    <button type="submit" className="btn btn-rescure" disabled={processing}>Update</button>                             
                                </div>
                    </form>
                    )
                }
                   
                </div>
      </div>
        {
            processing && (
                <div className="img-source">
                   <img src={CONSTANTS.ORANGE_PROCESSING} className="proc" />
                </div>
            )
        }
      </div>
    </Dialog>
  )
}

export default DlgEditUser