import React, { useEffect, useState } from 'react'
import DlgCreateUsers from '../Components/Dialogs/DlgCreateUsers';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as CONSTANTS from "../CONSTANTS";
import axios from 'axios';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import DlgListUserAdmin from '../Components/Dialogs/DlgListUserAdmin';
import DlgDisplayListClinic from '../Components/Dialogs/DlgDisplayListClinic';
import DlgDisplayListFranchise from '../Components/Dialogs/DlgDisplayListFranchise';

function UsersList() {

  const {user}                                             = useSelector((state) => state.auth);

  const [globalFilter, setGlobalFilter]                        = useState('');
  const [showUserCreation, setShowUserCreation]                = useState();
  const [showUserAdmins, setShowUserAdminstrator]              = useState(false);
  const [userList, setUsersList]                               = useState([]);
  const [userListArchive, setUsersListArchive]                 = useState([]);
  const [selectedPerson, setSelectedPerson]                    = useState(null);

  const [showClinicDialogue, setClinicDialogueList]            = useState(false);
  const [showFranchiseDialogue, setFranchiseDialogueList]      = useState(false);

  useEffect(() => {
    getAllUsersNotAdmin();
  },[]);

  function handleTableFilter(event) {
   
      const newData = userListArchive.filter(row => {
      const searchText = event.toLowerCase();
      
        return ( 
          row.phonenumber.toLowerCase().includes(searchText) ||
          row.name.toLowerCase().includes(searchText) ||
          row.surname.toLowerCase().includes(searchText) ||
          row.profession.toLowerCase().includes(searchText) ||
          row.email.toLowerCase().includes(searchText) )
      });
      setUsersList(newData);
  }
  //////////___
  const header = (
    <div className="table-header flex justify-content-between">
      <div className="mx-0 my-1">
        <span className="download-short" onClick={() => setClinicDialogueList(true)}>View Clinic List</span>
        <span className="download-short" onClick={() => setFranchiseDialogueList(true)}>View Franchise List</span>
        <span className="download-short" onClick={() => downloadExcelContent()}>Download Excel</span>
        <span className="download-short" onClick={() => downloadExcelDailyVisit()}>Download Excel Daily Visit</span>
        <span className="download-short" onClick={() => downloadExcelUnRSVPeed()}>Download None RSVP</span>
      </div>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" onInput={(e) => handleTableFilter(e.target.value)} placeholder="Search Users..." />
      </span>
    </div>
  );

 //////////___
  const linkUsersTemplate = (rowData) => {
    return <Link to={"/users/" + rowData._id} className="btn btn-rescure btsm1">View</Link> 
  }
  //////////___
  /////////____
  const displayRSVPStatus = (rowData) => {     
    return rowData.rsvp.status ? <div className="rsvp rsvtrue">COMPLETE</div>: <div className="rsvp rsvfalse"> INCOMPLETE</div>;
  }
  /////////____

  const getAllUsersNotAdmin = async () => {
 
    try{
        const res = await axios.get(CONSTANTS.API_URL +"users/list/no/admin", {
             headers: {
                 token: "Bearer "+ user.accessToken
             }
         });

         setUsersList(res.data);
         setUsersListArchive(res.data);
    }catch(erros){
     console.log(erros);     
    }
  }

  const downloadExcelContent = () => {
    console.log(userListArchive);

      const dataObject = userListArchive
        .filter(user => user.rsvp.status && !user.rsvp.content.visit.day_visit) 
        .map(user => {

        const rowData = {
          "name": user.name,
          "surname": user.surname,
          "email": user.email,
          "phonenumber": user.phonenumber,
          "profession": user.profession,
          "idnumber": user.idnumber,
          "dietary": user.dietary,
          "Conference type": user.rsvp.content.conference_type,
          "Pharmacy store": user.rsvp.content.pharmacy_store,
          "PCDT Pharmacist": user.rsvp.content.PCDT.pcdt_pharmacist,  
          "PCDT clinic": user.rsvp.content.PCDT.pcdt_clinic,       
          "Spouse coming": user.rsvp.content.spouse.spouse_coming,
          "Spouse name": user.rsvp.content.spouse.spouse_name,
          "Spouse id": user.rsvp.content.spouse.spouse_id,
          "Spouse pharmacy worker": user.rsvp.content.spouse.spouse_pharmacy_worker,
          "Spouse dietary": user.rsvp.content.spouse.spouse_dietary,
          "Spouse designation": user.rsvp.content.spouse.spouse_designation,
          "Flight date": user.rsvp.content.flight_date,
          "Flight": user.rsvp.content.flight,
          "golf": user.rsvp.content.golf,
          "Sharing room": user.rsvp.content.sharing_room,
          "Sharing Id": user.rsvp.content.share_id,
          "Upgrade": user.rsvp.content.upgrade,
          "Any comment": user.rsvp.content.any_comment,
        };
        return rowData;
      });

      // Create workbook and worksheet
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(dataObject);

      XLSX.utils.book_append_sheet(workbook, worksheet, 'TLC Franchise');

      // Generate Excel file
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        // Save file
      const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
      const fileName = 'TLC_USERS_'+Math.floor(Date.now() / 1000)+'.xlsx';
      saveAs(blob, fileName);
  }

  const downloadExcelDailyVisit = () => {
    console.log(userListArchive);

      const dataObject = userListArchive
        .filter(user => user.rsvp.status && user.rsvp.content.visit.day_visit) 
        .map(user => {

        const visitsString = user.rsvp.content.visit.selected_visits.map(visit => `${visit.date} - ${visit.label} ${visit.price}`).join(', ');

        const rowData = {
          "name": user.name,
          "surname": user.surname,
          "email": user.email,
          "phonenumber": user.phonenumber,
          "profession": user.profession,
          "idnumber": user.idnumber,
          "dietary": user.dietary,
          "Conference type": user.rsvp.content.conference_type,
          "Pharmacy store": user.rsvp.content.pharmacy_store,
          "Visits": visitsString,

        };
        return rowData;
      });

      // Create workbook and worksheet
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(dataObject);

      XLSX.utils.book_append_sheet(workbook, worksheet, 'TLC Franchise');

      // Generate Excel file
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        // Save file
      const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
      const fileName = 'TLC_DAILY_VISITS_'+Math.floor(Date.now() / 1000)+'.xlsx';
      saveAs(blob, fileName);
  }

  const downloadExcelUnRSVPeed = () => {
    
    const dataObject = userListArchive
        .filter(user => !user.rsvp.status) 
        .map(user => {

        const rowData = {
          "name": user.name,
          "surname": user.surname,
          "email": user.email,
          "phonenumber": user.phonenumber,
          "profession": user.profession,
          "dietary": user.dietary,
        };
        return rowData;
      });

      // Create workbook and worksheet
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(dataObject);

      XLSX.utils.book_append_sheet(workbook, worksheet, 'TLC Franchise');

      // Generate Excel file
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        // Save file
      const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
      const fileName = 'TLC_NORSVP_'+Math.floor(Date.now() / 1000)+'.xlsx';
      saveAs(blob, fileName);
  }
  return (
    <div className="main-standard">
      <h2 className="lbl-title-page">Users</h2>
      <div className="card">
         <div className="card-body">
            <div className="row-box">
               <DlgCreateUsers 
                    setShowUserCreation={setShowUserCreation} 
                    showUserCreation={showUserCreation} 
                    person={user} 
                  />
             
               <DlgListUserAdmin 
                    setShowUserAdminstrator={setShowUserAdminstrator} 
                    showUserAdmins={showUserAdmins} 
                    person={user} 
                  />
                
                <DlgDisplayListClinic
                  showClinicDialogue={showClinicDialogue} 
                  setClinicDialogueList={setClinicDialogueList}
                  person={user} 
                  />

                <DlgDisplayListFranchise
                  setFranchiseDialogueList={setFranchiseDialogueList}
                  showFranchiseDialogue={showFranchiseDialogue}
                  person={user} 
                  />
                 <Button
                      label="Create User"
                      className="btn btn-rescure btsm1 mr-2"
                      onClick={() => setShowUserCreation(true)}
                    />
            </div>
            <div className="row-data">
               {
                  userList && (
                    <DataTable
                      value={userList}
                      header={header}
                      className="min-w-full"
                      paginator rows={20} rowsPerPageOptions={[20, 30, 40]}
                      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
                      responsiveLayout="scroll"
                      globalFilter={(globalFilter) ? globalFilter : ''}
                      selection={selectedPerson}
                      onSelectionChange={e => setSelectedPerson(e.value)}
                      rowClassName={(rowData) => rowData.rsvp.seen ? 'se-processed' : 'unse-processed'}
                    >
                        <Column field="phonenumber" header="Phone Number"></Column>
                        <Column field="name" header="Name"></Column>
                        <Column field="surname" header="Surname"></Column>
                        <Column field="email" header="Email"></Column>
                        <Column field="profession" header="Profession"></Column>   
                        <Column header="RSVP" body={displayRSVPStatus}></Column>  
                        <Column header="View" body={linkUsersTemplate}></Column>           
                    </DataTable>
                  )
              }
            </div>
            <div className="bottom-buttons">
                   <Button
                      label="View Admins"
                      className="btn btn-rescure btsm1 mr-2"
                      onClick={() => setShowUserAdminstrator(true)}
                    />
            </div>
         </div>
       </div>
      </div>

  )
}

export default UsersList