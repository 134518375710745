import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import { faHome, faPlane, faStore, faUsers , faMicrophone, faBarcode, faBolt, faClock, faStop} from '@fortawesome/free-solid-svg-icons'
import './sidebar.css';
import HeaderLogo from './../../assets/large_logo.png';


function Sidebar({member}) {
    const [sideClass, setSideClass] = useState("full-side");
  //console.log("Side Content");
 // console.log(member);
    
  return (
    <div className={"bs-side " + sideClass}>
        <div className="sidebarMenu">
            <div className="slide-head-logo">
            <img src={HeaderLogo} className="logo-one-inside" alt="Mevent" />
            </div>
            <div className="sidebarTitle logo-mobile"><FontAwesomeIcon icon={faBarcode} /></div>  
            <ul>
                <li className="sidebarListItem">
                    <div className="col-item-block">
                        <Link to="/" className="link-nav-item-side">
                        <span className="sd-icon"><FontAwesomeIcon icon={faHome} /></span> 
                        <span className="name-Item">HOME</span>
                        </Link>
                    </div>
                </li>

                <li className="sidebarListItem">
                    <div className="col-item-block">
                    <Link to="/users" className="link-nav-item-side">
                            <span className="sd-icon"> <FontAwesomeIcon icon={faUsers} /></span> 
                            <span className="name-Item">Users</span>
                        </Link>
                    </div>
                </li>
                
                <li className="sidebarListItem">
                    <div className="col-item-block">
                       <Link to="/sponsor" className="link-nav-item-side">
                            <span className="sd-icon"> <FontAwesomeIcon icon={faBolt} /></span> 
                            <span className="name-Item">Sponsor</span>
                        </Link>
                    </div>
                </li>
           
                <li className="sidebarListItem">
                    <div className="col-item-block">
                       <Link to="/speakers" className="link-nav-item-side">
                            <span className="sd-icon"> <FontAwesomeIcon icon={faMicrophone} /></span> 
                            <span className="name-Item">Speakers</span>
                        </Link>
                    </div>
                </li>
                <li className="sidebarListItem">
                    <div className="col-item-block">
                       <Link to="/events" className="link-nav-item-side">
                            <span className="sd-icon"> <FontAwesomeIcon icon={faStore} /></span> 
                            <span className="name-Item">Events</span>
                        </Link>
                    </div>
                </li>
                <li className="sidebarListItem">
                    <div className="col-item-block">
                       <Link to="/flights" className="link-nav-item-side">
                            <span className="sd-icon"> <FontAwesomeIcon icon={faPlane} /></span> 
                            <span className="name-Item">Plane</span>
                        </Link>
                    </div>
                </li>
                <li className="sidebarListItem">
                    <div className="col-item-block">
                       <Link to="/decliner" className="link-nav-item-side">
                            <span className="sd-icon"> <FontAwesomeIcon icon={faStop} /></span> 
                            <span className="name-Item">Decliner</span>
                        </Link>
                    </div>
                </li>
                <li className="sidebarListItem">
                    <div className="col-item-block">
                       <Link to="/notifications" className="link-nav-item-side">
                            <span className="sd-icon"> <FontAwesomeIcon icon={faClock} /></span> 
                            <span className="name-Item">Notifications</span>
                        </Link>
                    </div>
                </li>
            </ul>
        </div>
    </div>
  )
}

export default Sidebar