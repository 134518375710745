import React, { useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { toast } from 'react-toastify';

function DlgCreateUsers({showUserCreation, setShowUserCreation, person}) {
    const [processing, setProcessing]                            = useState(false);

    const nameRef                                = useRef();
    const surnameRef                             = useRef();
    const emailRef                               = useRef();
    const phonenumberRef                         = useRef();
    const professionRef                          = useRef();
    const passwordRef                            = useRef();
    const idnumberRef                            = useRef();

     /*
    const supplierRef                            = useRef();    
    const bioRef                                 = useRef();    
    */

    async function HandleCreatingUser(e) {
        e.preventDefault();  
        setProcessing(true);   
    
        try{
            const userContent = {
                "name" : nameRef.current.value,
                "surname" : surnameRef.current.value,
                "email" : emailRef.current.value,
                "password" : passwordRef.current.value,
                "profilePic" : "-",
                "roles" : "standard",
                "bio" : "",
                "idnumber": idnumberRef.current.value,
                "phonenumber" : phonenumberRef.current.value,
                "profession" : professionRef.current.value,
                "approve" : true
               }
         
         
               const responseReg = await axios.post(CONSTANTS.API_URL+"auth/register", userContent);
   
               setProcessing(false);
               e.target.reset();
               toast.success("You successfully created a user.")
        } catch (err){
    
          console.log(err);
          setProcessing(false);
       
        }       
      }
  return (
    <Dialog header="Create User" visible={showUserCreation} onHide={() => setShowUserCreation(false)} style={{ width: '50vw' }}
    footer={
    <div>      
        <Button className="btn btn-picky" label="Close" onClick={() => setShowUserCreation(false)} />
    </div>
    }>
    <div className="modal-body-content">
      <div className="form-item form-pharm">
          <form onSubmit={HandleCreatingUser}>
                  <div className="form-group">
                          <div className="lbldesc">Phone*:</div>
                          <input type="text" className="form-control wide100" ref={phonenumberRef}  required/>
                    </div>  
                    <div className="form-group">
                          <div className="lbldesc">Email*:</div>
                          <input type="email" className="form-control wide100" ref={emailRef} required/>
                    </div>
                    <div className="form-group">
                          <div className="lbldesc">Name*:</div>
                          <input type="text" className="form-control wide100" ref={nameRef} required/>
                    </div>
                    
                    <div className="form-group">
                          <div className="lbldesc">Surname*:</div>
                          <input type="text" className="form-control wide100" ref={surnameRef} required/>
                    </div> 
                    <div className="form-group">
                          <div className="lbldesc">Job Title*:</div>
                          <input type="text" className="form-control wide100" ref={professionRef} required/>
                    </div>   
                    <div className="form-group">
                          <div className="lbldesc">Password*:</div>
                          <input type="text" className="form-control wide100" ref={passwordRef} defaultValue="PASSWORD1" required/>
                    </div>  
                    
                    <div className="form-group">
                          <div className="lbldesc">ID Number:</div>
                          <input type="text" className="form-control wide100" ref={idnumberRef} />
                    </div>   
                    <div className="form-group">
                          <button type="submit" className="btn btn-rescure" disabled={processing}>Create</button>                             
                    </div>
          </form>
      </div>
        {
            processing && (
                <div className="img-source">
                   <img src={CONSTANTS.BLUE_PROCESSING} className="proc" />
                </div>
            )
        }
      </div>
    </Dialog>
  )
}

export default DlgCreateUsers