import './formstyle.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./Pages/Login";
import Dashboard from "./Pages/Dashboard";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Home from "./Pages/Home";
import UsersList from "./Pages/UsersList";
import EventsDetails from "./Pages/EventDetails";
import Events from "./Pages/Events";
import Profile from "./Pages/Profile";
import Flights from "./Pages/Flights";
import Supplier from './Pages/Suppliers';
import SuppliersDetails from './Pages/SuppliersDetails';
import Notifications from './Pages/Notifications';
import Decliners from './Pages/Decliners';
import UserDetails from './Pages/UserDetails';
import Speakers from './Pages/Speakers';

function App() {
  return (
    <>
     <Router>
        <div className="main-container">
          <Routes>              
             <Route path="/login" element={<Login />}/>  
             <Route element={<Dashboard />}>
                 <Route path="/" element={<Home/>}/> 
                 <Route path="/dashboard" element={<Home/>}/> 
                 <Route path="/users" element={<UsersList/>}/> 
                 <Route path="/users/:id" element={<UserDetails />} />
                 <Route path="/event/:id" element={<EventsDetails/>}/> 
                 <Route path="/events" element={<Events/>}/> 
                 <Route path="/speakers" element={<Speakers/>} /> 
                 <Route path="/profile" element={<Profile/>}/> 
                 <Route path="/flights" element={<Flights/>}/> 
                 <Route path="/sponsor" element={<Supplier/>}/> 
                 <Route path="/sponsor/:id" element={<SuppliersDetails/>}/> 
                 <Route path="/decliner" element={<Decliners />} />
                 <Route path="/notifications" element={<Notifications />} />
             </Route>
          </Routes>
        </div>
     </Router>
     <ToastContainer />
    </>
  );
}

export default App;
