import React, { useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

function DlgEditAgenda({showEditeAgendaEventItem, setShowEditeAgendaEventItem, selectedAgenda, currentEvent, person}) {
    const [processing, setProcessing]                            = useState(false);
    const [speakerOption, setSpeakerOption]                      = useState(false);


    const [speakerList, setSpeakersList]                         = useState([]);
    const [agendaType, setAgendaType]                            = useState('normal');
    const [agendaTypeNumber, setAgendaTypeNumber]                = useState('1');
    const [selectedSpeakers, setSelectedSpeakers]                = useState([]);
    const [binderFilesList, setBinderFilesList]                  = useState([]);
    const [msgFeed, setMsgFeed]                                  = useState('');
    const [msgFeedClass, setMsgFeedClass]                        = useState('');
  
    const agendaTaskRef                          = useRef();
    const startTimeRef                           = useRef("");
    const endTimeRef                             = useRef("");    
    const agendaDateRef                          = useRef("");
    const agendaDayRef                           = useRef("");

    const nameBinderRef                          = useRef();
    const agendaBinderUploadRef                  = useRef();

    useEffect(() => {
        collectSpeakers();
    },[speakerOption])

    useEffect(() => {
        collectAllBinders();
    },[selectedAgenda])

    const collectSpeakers = async () => {
        try {
           
            const res = await axios.get(CONSTANTS.API_URL +"events/speakers/list");
           
            setSpeakersList(res.data);
        // setGetTheEvents(true);
       } catch (err) {
            console.log(err);            
       }
    }

    const collectAllBinders = async () => {
        try {     
        
            if(selectedAgenda != undefined){
                const obAgenda = {
                    "eventid" : currentEvent.event_code,
                    "agendaid" : selectedAgenda._id
                }

                //console.log(obAgenda);
                const res = await axios.put(CONSTANTS.API_URL +"events/binders/agenda/list/", obAgenda);
           
                   setBinderFilesList(res.data);
            }
            
           //console.log(res.data);
       } catch (err) {
            console.log(err);            
       }
    }
    const handleAgendaTypeChange = (event) => {
        setAgendaType(event.target.value);
        
        //If speaker is chosen, set the speaker state variable to true
        if (event.target.value === 'speaker') {
            setSpeakerOption(true);
            setAgendaTypeNumber("2")
        } else {
            setSpeakerOption(false);
            setAgendaTypeNumber("1")
        }
    }

    async function HandleEditTheAgenda(e) {
        e.preventDefault();  
        setProcessing(true);   
    
        try{
      
            setMsgFeedClass("");
                var objectEvent = {}

                    objectEvent = {
                        "agenda_type"  : agendaTypeNumber,
                        "title" : agendaTaskRef.current.value,
                        "start" : startTimeRef.current.value,
                        "end" : endTimeRef.current.value,
                        "speakerIds"   : selectedSpeakers,
                        "event_date"  : agendaDateRef.current.value,    
                        "agenda_number": parseInt(agendaDayRef.current.value),           
                      }
         
                    objectEvent = {
                        "agendaID" : selectedAgenda._id,
                        "data" : objectEvent
                    }
                           
           
                const content = await axios.put(CONSTANTS.API_URL+"events/update/agenda/event", objectEvent, {
                    headers:{
                        "token": "Bearer " + person.accessToken
                    }
                });
                
                //console.log(content.data);
                setMsgFeed("Successully updated this Agenda.");
                setMsgFeedClass("alert-success");
                e.target.reset();
             
           //setUserFound(false);
           setProcessing(false);   
          // 
        } catch (err){
          console.log(err);
          setProcessing(false);   
        }       
    }

    const handleCheckboxChangeSpeaker = (subid) => {

        const isSelected = selectedSpeakers.includes(subid);
        if (isSelected) {
            // If selected, remove the speaker from the list
            setSelectedSpeakers((prevSelected) =>
              prevSelected.filter((id) => id !== subid)
            );
          } else {
            // If not selected, add the speaker to the list
            setSelectedSpeakers((prevSelected) => [...prevSelected, subid]);
          }
    }

    async function HandleBinderUpdate(e) {
        e.preventDefault();  
        setProcessing(true);   
    
        try{
      
            if(agendaBinderUploadRef.current.files[0]){
                const formData = new FormData();
                formData.append('binderurl',  agendaBinderUploadRef.current.files[0]);
                const response = await axios.post('https://mevent.co.za/meventphone/upload.php', formData);    
      
                if(response.status == 200){
                  setMsgFeedClass("");
                  const objectBinder = {
                    "binder_url"    : response.data.imageUrl,
                    "name"       : nameBinderRef.current.value,
                    "eventid"     : currentEvent.event_code,
                    "agendaid"     : selectedAgenda._id,           
                  }
      
                  console.log(objectBinder);
                  const content = await axios.post(CONSTANTS.API_URL+"events/binder/creation", objectBinder, {
                    headers: {
                         token: "Bearer "+ person.accessToken
                     }
                  });
                  console.log(content.data);
                 // console.log(content.data);
      
                  e.target.reset();
                  toast.success("You successfully uploaded an file/resource.");
                }else {
                  setMsgFeed("Something went wrong with uploading the resource, please try again later ");
                  setMsgFeedClass("alert-success");
                }
            }
           /*
                const content = await axios.put(CONSTANTS.API_URL+"events/update/agenda/event", objectEvent, {
                    headers:{
                        "token": "Bearer " + person.accessToken
                    }
                });
                
                //console.log(content.data);
                setMsgFeed("Successully updated this Agenda.");
                setMsgFeedClass("alert-success");
                e.target.reset();
             */
           //setUserFound(false);
           setProcessing(false);   
        
        } catch (err){
          console.log(err);
          setProcessing(false);   
        }       
    }
  return (
    <Dialog header="Edit This Agenda" visible={showEditeAgendaEventItem} onHide={() => setShowEditeAgendaEventItem(false)} style={{ width: '50vw' }}
        footer={
        <div>      
            <Button className="btn btn-picky" label="Close" onClick={() => setShowEditeAgendaEventItem(false)} />
        </div>
      }>
      <div className="modal-body-content">
      <div className="data-item list-pharm-users">
           <div className="form-item form-pharm block-form-sect">
                {
                    selectedAgenda && (
                    <form onSubmit={HandleEditTheAgenda}>  
                          <div className="flexme">
                            <div className="flexwidth75">
                                    <div className="form-group">
                                        <div className="lbldesc">Agenda*:</div>
                                        <input type="text" className="form-control wide100" ref={agendaTaskRef} placeholder="Task..." defaultValue={selectedAgenda.title} required/>
                                    </div> 
                                    <div className="form-group">
                                        <div className="lbldesc">Start Time:</div>
                                        <input type="time" className="form-control wide100" ref={startTimeRef} defaultValue={selectedAgenda.start_time} required/>
                                    </div> 
                                    <div className="form-group">
                                        <div className="lbldesc">End Time*:</div>
                                        <input type="time" className="form-control wide100" ref={endTimeRef} defaultValue={selectedAgenda.end_time} required/>
                                    </div> 
                                    <div className="form-group">
                                        <div className="lbldesc">Date*:</div>
                                        <input type="date" className="form-control wide100" ref={agendaDateRef} defaultValue={selectedAgenda.event_date} required/>
                                    </div> 
                                    <div className="form-group">
                                        <div className="lbldesc">Day:</div>
                                        <input type="number" className="form-control wide100" ref={agendaDayRef} min={1} defaultValue={selectedAgenda.agenda_number}  required/>
                                    </div> 
                            </div>
                            <div className="flexwidth33">
                                    <div className="form-group agenda-radios pd10">
                                        <div className="lbldesc">Agenda Type*:</div>
                                            <label>
                                                <input type="radio" value="normal" checked={agendaType === 'normal'} onChange={handleAgendaTypeChange} />
                                                <span className="lb-ag"> Normal </span>
                                            </label>
                                            <label>
                                                <input type="radio" value="speaker" checked={agendaType === 'speaker'} onChange={handleAgendaTypeChange} />
                                                <span className="lb-ag"> Speaker</span>
                                            </label>
                                        
                                    </div>
                                    {
                                        speakerOption && (
                                            <div className="form-group pd10">
                                                    Select Speaker
                                                    {speakerList.map((speaker) => (
                                                        <div key={speaker._id} className="chkbx-small">
                                                        <input
                                                            type="checkbox"
                                                            id={`speaker-${speaker._id}`}
                                                            onChange={() => handleCheckboxChangeSpeaker(speaker.subid)}
                                                        />
                                                        <label className="spk-lb2" htmlFor={`speaker-${speaker._id}`}>
                                                            {speaker.name} {speaker.surname} - {speaker.title}
                                                        </label>
                                                        </div>
                                                    ))}
                                            </div>
                                        )
                                    }
                            </div>
                        </div>
                           
                           
                            <div className="form-bt-row">
                                <button type="submit" className="btn btn-rescure" disabled={processing}>Update</button>                             
                            </div>
                    </form>
                    )
                }  
                             
                    <div className="mgtop10">
                        {
                            msgFeed && (
                                <div className={"alert " + msgFeedClass  }>
                                    {msgFeed}
                                </div>
                            )
                        }
                    </div>  
            </div>
            <div className="form-item form-pharm block-form-sect">
                Agenda Binders 
                <div className="agenda-work">
                   <div className="flexme">
                        <div className="flexwidth50">
                                {
                                    binderFilesList && (
                                        <ul>
                                            {
                                                binderFilesList.map((binder, index) => {
                                                    return (<li key={index}><Link to={binder.binder_url} target="_blank">{binder.name}</Link></li>)
                                                })
                                            }
                                        </ul>
                                    )
                                }
                        </div>
                        <div className="flexwidth50">
                            <form onSubmit={HandleBinderUpdate}>  
                               <div className="form-group">
                                    <div className="lbldesc">Resource Name:</div>
                                    <input type="text" className="form-control wide100" ref={nameBinderRef} required/>
                                </div> 
                                <div className="form-group">
                                    <div className="lbldesc">Upload Resource:</div>
                                    <input type="file" className="form-control wide100" ref={agendaBinderUploadRef} required/>
                                </div> 
                                <div className="form-bt-row">
                                    <button type="submit" className="btn btn-rescure" disabled={processing}>Add Resource</button>                             
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
      </div>
        {
            processing && (
                <div className="img-source">
                   <img src={CONSTANTS.SMALL_PROCESSING} className="proc" />
                </div>
            )
        }
      </div>
    </Dialog>
  )
}

export default DlgEditAgenda