import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import * as CONSTANTS from "../CONSTANTS";

function Decliners() {
    const {user}                                                        = useSelector((state) => state.auth);

    const [declineList, setDeclineList]                                  = useState([]);
    const [declineListArchive, setDeclineListArchive]                    = useState([]);
    const [globalFilter, setGlobalFilter]                            = useState('');
    const [selectDecline, setSelectedEven]                            = useState(null);

    useEffect(() => {
        getAllDecliners()
    },[]);

    const getAllDecliners = async () => {

        try{
            const res = await axios.get(CONSTANTS.API_URL +"factories/decline/list");
             console.log(res);
            // console.log("Evenacies");
             console.log(res.data);
             setDeclineList(res.data);
             setDeclineListArchive(res.data);
        }catch(erros){
         console.log(erros);     
        }
    }

       function handleTableFilter(event) {
   
        const newData = declineListArchive.filter(row => {
            const searchText = event.target.value.toLowerCase();
          return ( 
            row.name.toLowerCase().includes(searchText) ||
            row.surname.toLowerCase().includes(searchText) ||
            row.email.toLowerCase().includes(searchText) )
        });
        setDeclineList(newData);
    }
    //////////___
    const header = (
      <div className="table-header flex justify-content-between">
        <h4 className="mx-0 my-1"></h4>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => handleTableFilter(e.target.value)} placeholder="Search Person..." />
        </span>
      </div>
    );

  return (
    <div className="main-standard">
      <h2 className="lbl-title-page">Decliners</h2>
      <div className="card">
            <div className="card-body">
                    {
                        declineList && (
                           
                                <DataTable
                                    value={declineList}
                                    header={header}
                                    className="min-w-full"
                                    paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} event"
                                    responsiveLayout="scroll"
                                    globalFilter={(globalFilter) ? globalFilter : ''}
                                    selection={selectDecline}
                                    onSelectionChange={e => setSelectedEven(e.value)}
                                  >                                  
                                    <Column field="name" header="Title"></Column>
                                    <Column field="surname" header="Surname"></Column>
                                    <Column field="email" header="Email"></Column>
                                    <Column field="reason" header="Reason"></Column>                  
                                </DataTable>
                            
                        )

                    }
            </div>
         </div>      
    </div>
  )
}

export default Decliners