import React, { useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { toast } from 'react-toastify';

function DlgEditFlight({showEditFlightDetails, setShowEditFlightDetails, flightItem, person}) {
    const [processing, setProcessing]                            = useState(false);

    const provinceRef                            = useRef();
    const titleRef                               = useRef();
    const initialRef                             = useRef();

    const travelDate1Ref                             = useRef();
    const travelTime1Ref                             = useRef();
    const travelLoc1Ref                              = useRef();

    const travelDate2Ref                             = useRef();
    const travelTime2Ref                             = useRef();
    const travelLoc2Ref                              = useRef();

    const travelDate3Ref                             = useRef();
    const travelTime3Ref                             = useRef();
    const travelLoc3Ref                              = useRef();


    async function HandleUpdatingFlightData(e) {
        e.preventDefault();  
        setProcessing(true);   
    
        try{
            const flightsContent = {
                "title" : titleRef.current.value,
                "province" : provinceRef.current.value,
                "initial": initialRef.current.value,
                "flightoption": []
               }

               const travel1 = {
                        "travelDate"  : travelDate1Ref.current.value,
                        "travelTime"  : travelTime1Ref.current.value,
                        "travelLocation"  : travelLoc1Ref.current.value,
                }
                flightsContent.flightoption.push(travel1);

                const travel2 = {
                    "travelDate"  : travelDate2Ref.current.value,
                    "travelTime"  : travelTime2Ref.current.value,
                    "travelLocation"  : travelLoc2Ref.current.value,
               }
            
               if(travelDate2Ref.current.value.length > 0 && (travelTime2Ref.current.value.length > 0) ){
                 flightsContent.flightoption.push(travel2);
               }

               const travel3 = {
                "travelDate"  : travelDate3Ref.current.value,
                "travelTime"  : travelTime3Ref.current.value,
                "travelLocation"  : travelLoc3Ref.current.value,
               }
        
               if((travelDate3Ref.current.value.length > 0) && (travelTime3Ref.current.value.length > 0) ){
                flightsContent.flightoption.push(travel3);
               }

               const newFlightUpdate = {
                "flightID": flightItem._id,
                "data": flightsContent,
               }

               const responseReg = await axios.put(CONSTANTS.API_URL+"events/flight/update", newFlightUpdate, {
                    headers: {
                        token: "Bearer "+ person.accessToken
                    }
                });
            console.log(responseReg.data);
            
            toast.success("You successfully updated flight.");
            setProcessing(false);

        }catch(err){

        }
    }

  return (
    <Dialog header="Edit This Flight" visible={showEditFlightDetails} onHide={() => setShowEditFlightDetails(false)} style={{ width: '50vw' }}
    footer={
    <div>      
        <Button className="btn btn-picky" label="Close" onClick={() => setShowEditFlightDetails(false)} />
    </div>
    }>
    <div className="modal-body-content">
        <div className="data-item list-pharm-users">
            <div className="data-item list-flight">
                {
                    flightItem && (
                        <form onSubmit={HandleUpdatingFlightData}>
                                    
                                <div className="form-group">
                                    <div className="lbldesc">Airpot*:</div>
                                    <input type="text" className="form-control wide100" ref={titleRef}   defaultValue={flightItem.title} required/>
                                </div>     
                                <div className="form-group">
                                    <div className="lbldesc">Airpot Province:</div>
                                    
                                    <select  className="form-control wide100" ref={provinceRef} defaultValue={flightItem.province}>
                                        <option value="Eastern Cape">Eastern Cape</option>
                                        <option value="Free State">Free State</option>
                                        <option value="Gauteng">Gauteng</option>
                                        <option value="KwaZulu-Natal ">KwaZulu-Natal </option>
                                        <option value="Limpopo">Limpopo</option>
                                        <option value="Mpumalanga">Mpumalanga</option>
                                        <option value="Northern Cape ">Northern Cape </option>
                                        <option value="North West ">North West </option>
                                        <option value="Western Cape ">Western Cape </option>
                                    </select>
                                </div>   
                                <div className="form-group">
                                    <div className="lbldesc">Airpot Inital:</div>
                                    <input type="text" className="form-control wide100" ref={initialRef}  defaultValue={flightItem.initial}/>                         
                                </div>   
                                <div className="flexme">
                                    <div className="flexwidth33">
                                        <p className="opt">Option 1</p>
                                        <div className="form-group fr-dt-item">
                                            <div className="lbldesc">Airpot Date*:</div>
                                            <input type="date" 
                                                className="form-control wide100" 
                                                ref={travelDate1Ref} 
                                                defaultValue={flightItem.flightoption && flightItem.flightoption[0] ? flightItem.flightoption[0].travelDate : ""}
                                                required/> 
                                        </div>  
                                        <div className="form-group fr-dt-item">
                                            <div className="lbldesc">Airpot Time*:</div>
                                            <input type="text" 
                                                className="form-control wide100" 
                                                ref={travelTime1Ref} 
                                                defaultValue={flightItem.flightoption && flightItem.flightoption[0] ? flightItem.flightoption[0].travelTime : ""}
                                                required/> 
                                        </div>  
                                        <div className="form-group fr-dt-item">
                                            <div className="lbldesc">Airpot Location:</div>
                                            <input type="text" 
                                                className="form-control wide100" 
                                                ref={travelLoc1Ref} 
                                                defaultValue={flightItem.flightoption && flightItem.flightoption[0] ? flightItem.flightoption[0].travelLocation  : ""} 
                                                /> 
                                        </div>  
                                    </div>

                                    <div className="flexwidth33 fl-mid-block">
                                        <p className="opt">Option 2</p>
                                        <div className="form-group fr-dt-item">
                                            <div className="lbldesc">Airpot Date:</div>
                                            <input type="date" 
                                                className="form-control wide100" 
                                                ref={travelDate2Ref} 
                                                defaultValue={flightItem.flightoption && flightItem.flightoption[1] ? flightItem.flightoption[1].travelDate : ""} 
                                                /> 
                                        </div>  
                                        <div className="form-group fr-dt-item">
                                            <div className="lbldesc">Airpot Time:</div>
                                            <input type="text" 
                                                className="form-control wide100" 
                                                ref={travelTime2Ref} 
                                                defaultValue={flightItem.flightoption && flightItem.flightoption[1] ? flightItem.flightoption[1].travelTime : ""} 
                                                /> 
                                        </div>  
                                        <div className="form-group fr-dt-item">
                                            <div className="lbldesc">Airpot Location:</div>
                                            <input type="text" 
                                                className="form-control wide100" 
                                                ref={travelLoc2Ref} 
                                                defaultValue={flightItem.flightoption && flightItem.flightoption[1] ? flightItem.flightoption[1].travelLocation : ""} 
                                                /> 
                                        </div>  
                                    </div>

                                    <div className="flexwidth33">
                                        <p className="opt">Option 3</p>
                                        <div className="form-group fr-dt-item">
                                            <div className="lbldesc">Airpot Date:</div>
                                            <input type="date" className="form-control wide100" 
                                                ref={travelDate3Ref} 
                                                defaultValue={flightItem.flightoption && flightItem.flightoption[2] ? flightItem.flightoption[2]?.travelDate : ""} 
                                                /> 
                                        </div>  
                                        <div className="form-group fr-dt-item">
                                            <div className="lbldesc">Airpot Time:</div>
                                            <input type="text" className="form-control wide100" 
                                                ref={travelTime3Ref} 
                                                defaultValue={flightItem.flightoption && flightItem.flightoption[2] ? flightItem.flightoption[2].travelTime : ""}  
                                                /> 
                                        </div>  
                                        <div className="form-group fr-dt-item">
                                            <div className="lbldesc">Airpot Location:</div>
                                            <input type="text" className="form-control wide100" 
                                                ref={travelLoc3Ref} 
                                                defaultValue={flightItem.flightoption && flightItem.flightoption[2] ? flightItem.flightoption[2].travelLocation : ""} 
                                                /> 
                                        </div>  
                                    </div>
                                    
                                </div> 
                                
                                <div className="form-group">
                                    <button type="submit" className="btn btn-rescure" disabled={processing}>Update</button>                             
                                </div>
                    </form>
                    )
                }
                   
                </div>
      </div>
        {
            processing && (
                <div className="img-source">
                   <img src={CONSTANTS.ORANGE_PROCESSING} className="proc" />
                </div>
            )
        }
      </div>
    </Dialog>
  )
}

export default DlgEditFlight