
export const API_URL           = "https://mevent-phone-4f690ce5228b.herokuapp.com/api/";
export const SESSION_COOKIE    = "userXRPhoneMEVENTCjAdmin00";
export const UPLOAD_MEVPHONE   = "https://mevent.co.za/meventphone/upload.php";

/*
export const API_URL           = "http://localhost:55021/api/";
export const SESSION_COOKIE    = "meventadminmorePHONEX";
export const UPLOAD_MEVPHONE   = "https://mevent.co.za/meventphone/uploaddemo.php";
*/

export const SMALL_PROCESSING  = "https://api.picnick.co.za/assets/smallprocess.gif";
export const BLUE_PROCESSING   = "http://cjmarketing.co/image_directory/blue-load.gif";
export const ORANGE_PROCESSING = "https://cjmarketing.co/image_directory/orange_circles.gif";
export const IMAGE_PROFILE     = "https://newclient.cjdsa.co.za/images/base/profile.jpg";