import React, { useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { toast } from 'react-toastify';

function DlgPharmacyAdd({showBackendPharmacy, setShowBackendPharmacy, person}) {
    
  const [processing, setProcessing]                            = useState(false);
    
    const nameRef                               = useRef();
    const addressRef                            = useRef();

    async function HandleCreatingPharmacyData(e) {
        e.preventDefault();  
        setProcessing(true);   
    
        try{
            const pharmacysContent = {
                "name" : nameRef.current.value,
                "address" : addressRef.current.value,
               }
         
               console.log(pharmacysContent);
             
               const responseReg = await axios.post(CONSTANTS.API_URL+"factories/pharmacy/create", pharmacysContent, {
                    headers: {
                        token: "Bearer "+ person.accessToken
                    }
                });
               console.log(responseReg.data);
               setProcessing(false);
               e.target.reset();
               toast.success("You successfully created a pharmacy.");
                
        } catch (err){
    
          console.log(err);
          setProcessing(false);
       
        }       
      }


  return (
    <Dialog header="Create Pharmacy" visible={showBackendPharmacy} onHide={() => setShowBackendPharmacy(false)} style={{ width: '50vw' }}
        footer={
        <div>      
            <Button className="btn btn-picky" label="Close" onClick={() => setShowBackendPharmacy(false)} />
        </div>
        }>
    <div className="modal-body-content">
      <div className="data-item list-pharmacy">
          <form onSubmit={HandleCreatingPharmacyData}>
                   
                    <div className="form-group">
                          <div className="lbldesc">Title*:</div>
                          <input type="text" className="form-control wide100" ref={nameRef}  required/>
                    </div>     
                    <div className="form-group">
                          <div className="lbldesc">Address:</div>
                          <input type="text" className="form-control wide100" ref={addressRef}  />
                    </div>   
   
                    
                    <div className="form-group">
                          <button type="submit" className="btn btn-rescure" disabled={processing}>Create</button>                             
                    </div>
          </form>
      </div>
        {
            processing && (
                <div className="img-source">
                   <img src={CONSTANTS.ORANGE_PROCESSING} className="proc" />
                </div>
            )
        }
      </div>
    </Dialog>
  )
}

export default DlgPharmacyAdd