import React, { useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { toast } from 'react-toastify';

function DlgListUserAdmin({showUserAdmins, setShowUserAdminstrator, person}) {


  const [processing, setProcessing]                            = useState(false);
  const [msgFeed, setMsgFeed]                                  = useState('');
  const [msgFeedClass, setMsgFeedClass]                        = useState('');
  const [filteredUsers, setFilteredUsers]                      = useState([]);
  const [selectedUser, setSelectedUser]                        = useState(null);
  const [userfound, setUserFound]                              = useState(false);
  const [adminUsers, setAdminUsers]                            = useState([]);

  const nameRef                                    = useRef();
  const surnameRef                                 = useRef();
  const emailRef                                   = useRef();
  const phonenumberRef                             = useRef();
  const passwordRef                                = useRef();
  const professionRef                              = useRef();
  const idnumberRef                                = useRef();

  useEffect(() => {
   
    if(person){
        collectAdminUser();
    }
  },[])


  const collectAdminUser = async () => {
    // console.log(user);
 
    try{
        const res = await axios.get(CONSTANTS.API_URL +"users/list/is/admin", {
             headers: {
                 token: "Bearer "+ person.accessToken
             }
         });
         //console.log(res);
         console.log("ADmins");
         //console.log(res.data);
         setAdminUsers(res.data);
 
    }catch(erros){
     console.log(erros);     
    }
   }
  async function HandleCreatingAdminstrator(e) {
    e.preventDefault();  
    setProcessing(true);   

    try{

            const objecttadeShow = {
              "name"      : nameRef.current.value,
              "surname"   : surnameRef.current.value, 
              "email"     : emailRef.current.value,  
              "roles"     : "admin", 
              "password"  : passwordRef.current.value,  
              "phonenumber"  : phonenumberRef.current.value, 
              "profession"  : professionRef.current.value,   
              "bio"         :"",
              "idnumber"   : idnumberRef.current.value,         
            }

            console.log(objecttadeShow);
            const content = await axios.post(CONSTANTS.API_URL+"auth/register", objecttadeShow, {
              headers: {
                   token: "Bearer "+ person.accessToken
               }
            });

           console.log(content.data);

            e.target.reset();
            toast.success("You successfully created Admin.");
         
       setProcessing(false);   
      // 
    } catch (err){
      console.log(err);
      setProcessing(false);   
    }       
  }

  return (
    <Dialog header="Administration" visible={showUserAdmins} onHide={() => setShowUserAdminstrator(false)} style={{ width: '60vw' }}
      footer={
      <div>      
          <Button className="btn btn-picky" label="Close" onClick={() => setShowUserAdminstrator(false)} />
      </div>
      }>
    <div className="modal-body-content">
      <div className="form-item form-Even">                
        <div className="row rx-bs">
            <div className="col-md-7">
                <div className="mt-list">
                    <h5>Admin User</h5>
                    {
                        adminUsers && (
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Surname</th>
                                        <th>Email</th>
                                        <th>Phone Number</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        adminUsers.map((admin, index) => {
                                            return (<tr key={index}>
                                                <td>{admin.name}</td>
                                                <td>{admin.surname}</td>
                                                <td>{admin.email}</td>
                                                <td>{admin.phonenumber}</td>
                                            </tr>)
                                        })
                                    }
                                </tbody>
                            </table>
                        )
                    }
                </div>
            </div>
            <div className="col-md-5">
                <div className="dv-list">
                   <h5>Add Admin User</h5>
                     <form onSubmit={HandleCreatingAdminstrator}>                   
                            <div className="form-group">
                                <div className="lbldesc">Name*:</div>
                                <input type="text" className="form-control wide100" ref={nameRef} required/>
                            </div>
                            <div className="form-group">
                                <div className="lbldesc">Surname*:</div>
                                <input type="text" className="form-control wide100" ref={surnameRef} required/>
                            </div>
                            <div className="form-group">
                                <div className="lbldesc">Phone Number*:</div>
                                <input type="text" className="form-control wide100" ref={phonenumberRef} required/>
                            </div>
                            <div className="form-group">
                                <div className="lbldesc">Email*:</div>
                                <input type="email" className="form-control wide100" ref={emailRef} required/>
                            </div>
                            <div className="form-group">
                                <div className="lbldesc">Password*:</div>
                                <input type="text" className="form-control wide100" ref={passwordRef} defaultValue={"123Pass"}required/>
                            </div>
                            <div className="form-group">
                                <div className="lbldesc">ID Number:</div>
                                <input type="text" className="form-control wide100" ref={idnumberRef} />
                            </div>
                            <div className="form-group">
                                <div className="lbldesc">Profession:</div>
                                <input type="text" className="form-control wide100" ref={professionRef} required/>
                            </div>        
                            <div className="form-bt-row">
                                    <button type="submit" className="btn btn-rescure" disabled={processing}>Create</button>                             
                            </div>
                    </form>
                </div>
            </div>
        </div>
      </div>


        {
            processing && (
                <div className="img-source">
                   <img src={CONSTANTS.ORANGE_PROCESSING} className="proc" />
                </div>
            )
        }
 
      </div>
    </Dialog>
  )
}

export default DlgListUserAdmin