import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import './topnavbar.css';
import { useDispatch, useSelector } from 'react-redux';
import { logout, reset } from '../../reduxAuth/authSlice';

function Tobnavbar() {

  const navigate          = useNavigate();
  const dispatch          = useDispatch();

  const {user}                = useSelector((state) => state.auth)

  const onLogOut = () => {

    dispatch(logout());
    dispatch(reset());
    navigate("/login");
  }
  return (
    <div className="nav-bar">
        <div className="content-nav">
              <div className="title-dash-name">
                 {
                  user && (
                    <div className="stitle">
                       <span className="nm-labelings names1">{user.name}</span>  <span className="nm-labelings names2">{user.surname} </span>
                    </div>
                  )
                 }
              </div>              
        </div>
        <button className="btn btn-rescure-login" onClick={onLogOut}>
                  Logout
       </button>
    </div>
  )
}

export default Tobnavbar