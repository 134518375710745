import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import * as CONSTANTS from "../CONSTANTS";
import axios from 'axios';
import DlgCreateSupplier from '../Components/Dialogs/DlgCreateSupplier';
import { Link } from 'react-router-dom';

function Suppliers() {
    const {user}                                                    = useSelector((state) => state.auth);

    const [suppliersList, setSuppliersList]                         = useState([]);
    const [suppliersListArchive, setSuppliersListArchive]           = useState([]);
    const [showSuppliersCreation, setShowSuppliersCreation]         = useState();
    const [globalFilter, setGlobalFilter]                           = useState('');
    const [selectedSupplier, setSelectedSupplier]                   = useState(null);

    const [msgFeedClass, setMsgFeedClass]                          = useState('');
    
    useEffect(() => {
        getAllTheSuppliers();
       },[msgFeedClass]);

    function handleTableFilter(supplierText) {

        const newData = suppliersListArchive.filter(row => {
            const searchText = supplierText.toLowerCase();
          return ( 
            row.name.toLowerCase().includes(searchText) ||
            row.supcode.toLowerCase().includes(searchText))
        });
        setSuppliersList(newData);
    }
    ///////////////------->
     const header = (
        <div className="table-header flex justify-content-between">
          <h4 className="mx-0 my-1"></h4>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText type="search" onInput={(e) => handleTableFilter(e.target.value)} placeholder="Search Event..." />
          </span>
        </div>
      );
  
  ///////////////------->
  const viewImageTemplate = (rowData) => {
      return <img className="img-care " src={rowData.logo_image} />;
  }
  ///////////////------->
  ///////////////------->
     const linkSupplierTemplate = (rowData) => {
      return <Link to={"/sponsor/" + rowData._id} className="btn btn-rescure btsm1">View</Link> 
    }
 ///////////////------->
  const getAllTheSuppliers = async () => {

        try{
            const res = await axios.get(CONSTANTS.API_URL +"suppliers/assembly");
             
             setSuppliersList(res.data);
             setSuppliersListArchive(res.data);
    
        }catch(erros){
         console.log(erros);     
        }
    }
  return (
    <div className="main-standard">
       <h2 className="lbl-title-page">Suppliers</h2>
       <div className="card">
         <div className="card-body">
           <div className="row-box">
            <DlgCreateSupplier
                showSuppliersCreation={showSuppliersCreation} 
                setShowSuppliersCreation={setShowSuppliersCreation} 
                setMsgFeedClass={setMsgFeedClass}
                person={user} 
               />

              <Button
                    label="Create Supplier"
                    className="btn btn-rescure btsm1 mr-2"
                    onClick={() => setShowSuppliersCreation(true)}
                />
           </div>
           <div className="row-data">
              {
                  suppliersList && (
                    <DataTable
                        value={suppliersList}
                        header={header}
                        className="min-w-full"
                        paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} supplier"
                        responsiveLayout="scroll"
                        globalFilter={(globalFilter) ? globalFilter : ''}
                        selection={selectedSupplier}
                        onSelectionChange={e => setSelectedSupplier(e.value)}
                      >
                        <Column header="Image" body={viewImageTemplate}></Column>  
                        <Column field="name" header="Title"></Column>
                        <Column field="supcode" header="Supplier Code"></Column>
                        <Column header="View" body={linkSupplierTemplate}></Column>       
                    </DataTable>
                  )
               }
           </div>
         </div>
        </div>
    </div>
  )
}

export default Suppliers