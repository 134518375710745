import React, { useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import * as CONSTANTS from "../../CONSTANTS";
import * as XLSX from 'xlsx';
import axios from "axios";
import { toast } from 'react-toastify';

function DlgPharmacyImport({showBackendPharmacyImport, setShowBackendPharmacyImport, person}) {
    const [processing, setProcessing]                            = useState(false);

    const [fileContent, setFileContent]                           = useState([]);

    const [progressOn, setProgressOn]                                 = useState(false);
    const [progressAmount, setProgressAmount]                         = useState(0);
    const [msgProcessText, setMsgProcessText]                         = useState();
    const [style, setStyleAmount]                                     = useState({
        opacity: 1,
        width: `${progressAmount}%`
    });

    const [totalPharmacies, setTotalPharmacies]                       = useState();


    const handleFileUpload = (e) => {
        if(e.target.files.length > 0 ){
            console.log("File uplood");
           
            const reader = new FileReader();
            reader.readAsBinaryString(e.target.files[0]);
            reader.onload = (e) => {
                const data       = e.target.result;
                const workbook   = XLSX.read(data, {type: "binary"});
                const sheetName  = workbook.SheetNames[0];
                const sheet      = workbook.Sheets[sheetName];
                const parsedData = XLSX.utils.sheet_to_json(sheet);
                setFileContent(parsedData);  

                console.log(parsedData);
                setTotalPharmacies(parsedData.length)
            }
        }else {
            console.log("Empty");
        }
    }

    async function HandleCreatingPharmacyExcel(e) {
        e.preventDefault();  
        //setProcessing(true);   
    
        try{
          console.log(fileContent);
          var count = 0;
          setProgressOn(true);
          for (const fileItem of fileContent) {

              const pharmacysContent = {
                "name" : fileItem.pharmacy,
                "address" : "",
              }
              console.log(pharmacysContent);
              count++;

              const responseReg = await axios.post(CONSTANTS.API_URL+"factories/pharmacy/create", pharmacysContent, {
                  headers: {
                      token: "Bearer "+ person.accessToken
                  }
              });
              /////////____>
              setProgressAmount(Math.round((count / totalPharmacies) * 100));
                setStyleAmount({
                  opacity: 1,
                  width: `${Math.round((count / totalPharmacies) * 100)}%`
                });
                
              setMsgProcessText(responseReg.data.name);
              /////////____>
          }
          setMsgProcessText("Added Pharmacies: " + count);
         
               e.target.reset();
               toast.success("You successfully added the pharmacies.");
               
        } catch (err){
    
          console.log(err);
        
        }       
      }



  return (
    <Dialog header="Import Pharmacy List" visible={showBackendPharmacyImport} onHide={() => setShowBackendPharmacyImport(false)} style={{ width: '50vw' }}
        footer={
        <div>      
            <Button className="btn btn-picky" label="Close" onClick={() => setShowBackendPharmacyImport(false)} />
        </div>
        }>
       <div className="modal-body-content">
      <div className="data-item list-video">
          <form onSubmit={HandleCreatingPharmacyExcel}>   
                    <div className="form-group">
                        <div className="lbldesc">Add Excel Sheet: <sub>1 Column with heading <strong>pharmacy</strong></sub></div>
                        <div className="form-group">
                              <input 
                                  type="file"
                                  clasName="form-control"
                                  accept=".xlsx, .xls"
                                  onChange={handleFileUpload}
                                  required
                                  />
                          </div> 
                    </div>   
                  
                    <div className="form-group">
                          <button type="submit" className="btn btn-rescure" disabled={processing}>Create</button>                             
                    </div>
          </form>
      </div>
      <div className="mgtop10">
          {
          progressOn && (
              <div className="flexme">
                  <div className="progress">
                      <div className="progress-done" style={style}>
                          {progressAmount}%
                      </div>
                  </div>
                  <div className="msg-processing-item">
                      {msgProcessText}
                  </div>
              </div>
          )
      }
      </div>
        {
            processing && (
                <div className="img-source">
                   <img src={CONSTANTS.ORANGE_PROCESSING} className="proc" />
                </div>
            )
        }
       </div>
    </Dialog>
  )
}

export default DlgPharmacyImport