import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import * as CONSTANTS from "../CONSTANTS";
import axios from 'axios';
import { Button } from 'primereact/button';
import DlgCreateSpeakers from '../Components/Dialogs/DlgCreateSpeakers';

function Speakers() {
    const {user}                                                       = useSelector((state) => state.auth);

    const [getSpeakersList, setSpeakersList]                           = useState(false);
    const [showSpeakersCreation, setShowSpeakersCreation]              = useState(false);

    useEffect(() => {
        collectSpeakers();      
    },[])

    const collectSpeakers = async () => {

        try {
          
            const res = await axios.get(CONSTANTS.API_URL +"events/speakers/list");
            setSpeakersList(res.data);
       
           // setGetTheEvents(true);
          } catch (err) {
            console.log(err);        
          }    
      }
      

      const viewCurrectSpeaker = (speaker) => {
         console.log("_-_-_-_-_-_-_-_-_");
         console.log(speaker);
      }

  return (
    <div className="main-standard">
       <h2 className="lbl-title-page">Speakers</h2>
         <div className="card">
          <div className="card-body">
              <div className="data-cont">
                <DlgCreateSpeakers
                        setShowSpeakersCreation={setShowSpeakersCreation} 
                        showSpeakersCreation={showSpeakersCreation} 
                        person={user} 
                    />

                <Button
                    label="Create Speakers"
                    className="btn btn-rescure btsm1 mr-2"
                    onClick={() => setShowSpeakersCreation(true)}
                    />
               </div>

               <div className="cont-ride-slow pd10">
                        {
                          getSpeakersList && (
                            <div className="list-speakers-v2 row">
                              {
                                getSpeakersList.map((speaker, index) => {
                                  return (<div className="col-md-4 img-part-items" key={index} >
                                       <div className="griddy-profile">
                                        <div className="img-profile-single" style={{backgroundImage: `url(${speaker.img_url})`}}>
                                          </div>
                                          <div className="txt-content">
                                            <div className="lbl-name-lspeak">{speaker.name} {speaker.surname}</div>
                                            <div className="lbl-title-lspeak">{speaker.title} </div>
                                            <div className="lbl-bio-lspeak">
                                              <div className="accordion-mev">
                                                 <input type="checkbox" id={`accordion-${index}`} className="accordion-input" />
                                                 <label htmlFor={`accordion-${index}`} className="accordion-label">
                                                    <span className="accordion-title">Bio</span>
                                                  </label>
                                                  <div className="accordion-content">
                                                    {speaker.bio}
                                                  </div>
                                              </div>
                                              
                                            </div>
                                          </div>
                                       </div>
                                  </div>)
                                })
                              }
                            </div>
                          )
                        }
                </div>
          </div>
        </div>
    </div>
  )
}

export default Speakers