import React, { useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { toast } from 'react-toastify';


function DlgCreateSpeakers({showSpeakersCreation, setShowSpeakersCreation, person}) {
    const [processing, setProcessing]                            = useState(false);

    const [msgFeed, setMsgFeed]                                  = useState('');
    const [msgFeedClass, setMsgFeedClass]                        = useState('');

    const eventImageToUploadRef                  = useRef();
    const speakerNameRef                         = useRef();
    const speakerSurnameRef                      = useRef();
    const speakerEmailRef                        = useRef();
    const speakerBioRef                          = useRef();
    const speakerTitleRef                        = useRef();

    async function HandleCreatingTheSpeaker(e) {
        e.preventDefault();  
        setProcessing(true);   
    
        try{
      
          if(eventImageToUploadRef.current.files[0]){
    
            const formData = new FormData();
            formData.append('speakerprofile',  eventImageToUploadRef.current.files[0]);
    
              const response = await axios.post('https://mevent.co.za/meventphone/upload.php', formData);    
          

              if(response.status == 200){
                setMsgFeedClass("");
                
                const objectEvent = {
                  "profile"    : response.data.imageUrl,
                  "name"       : speakerNameRef.current.value,
                  "surname" : speakerSurnameRef.current.value,
                  "title" : speakerTitleRef.current.value,
                  "bio"   : speakerBioRef.current.value,
                  "email"  : speakerEmailRef.current.value,               
                }
    
                const content = await axios.post(CONSTANTS.API_URL+"events/speakers/add",objectEvent);
    
                //console.log(content.data);
                setMsgFeed("Successully added Speaker.");
                setMsgFeedClass("alert-success");
                e.target.reset();
                toast.success("You successfully created a speaker.");
              }else {
                setMsgFeed("Something went wrong with uploading the image, please try again later ");
                toast.error("Something went wrong with uploading the image, please try again later.");
                setMsgFeedClass("alert-success");
              }
          }
      
           //setUserFound(false);
           setProcessing(false);   
          // 
        } catch (err){
          console.log(err);
          setProcessing(false);   
        }       
      }

  return (
    <Dialog header="Create Speaker" visible={showSpeakersCreation} onHide={() => setShowSpeakersCreation(false)} style={{ width: '50vw' }}
    footer={
         <div>      
            <Button className="btn btn-picky" label="Close" onClick={() => setShowSpeakersCreation(false)} />
          </div>
        }> 
        <div className="modal-body-content">
           <div className="form-item form-pharm">
           <form onSubmit={HandleCreatingTheSpeaker}>   
               <div className="form-group">
                    <div className="lbldesc">Name*:</div>
                    <input type="text" className="form-control wide100" ref={speakerNameRef} required/>
                </div> 
                <div className="form-group">
                    <div className="lbldesc">Surname:</div>
                    <input type="text" className="form-control wide100" ref={speakerSurnameRef} required/>
                </div> 
                <div className="form-group">
                    <div className="lbldesc">Job Title*:</div>
                    <input type="text" className="form-control wide100" ref={speakerTitleRef} required/>
                </div> 
                <div className="form-group">
                    <div className="lbldesc">Email:</div>
                    <input type="text" className="form-control wide100" ref={speakerEmailRef} />
                </div> 
                <div className="form-group">
                    <div className="lbldesc">Bio:</div>
                    <textarea className="form-control wide100" ref={speakerBioRef}></textarea>
                </div> 
                <div className="form-group">
                        Select image to upload <small>(2 mb)</small>:
                    <input type="file" ref={eventImageToUploadRef} multiple="" accept="image/*" required/>
                </div>
                <div className="form-bt-row">
                    <button type="submit" className="btn btn-rescure" disabled={processing}>Create</button>                             
                </div>
            </form>
             
           </div>
         {
            processing && (
                <div className="img-source">
                   <img src={CONSTANTS.ORANGE_PROCESSING} className="proc" />
                </div>
            )
        }
    </div>
     </Dialog>
  )
}

export default DlgCreateSpeakers