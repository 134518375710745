import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import * as CONSTANTS from "../CONSTANTS";
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import DlgCreateFlight from '../Components/Dialogs/DlgCreateFlight';
import { Button } from 'primereact/button';
import DlgEditFlight from '../Components/Dialogs/DlgEditFlight';

function Flights() {
    const {user}                                                     = useSelector((state) => state.auth);
  
    const [processing, setProcessing]                                = useState(false);

    const [flightItem, setFlightItem]                                  = useState({});
    const [flightList, setFlightListing]                               = useState([]);
    const [flightListArchive, setflightListingArchive]                 = useState([]);

    const [showCreateFlightDetails, setShowCreateFlightDetails]        = useState(false);
    const [showEditFlightDetails, setShowEditFlightDetails]                = useState(false);
    
   // setEditFlightDetails
    const [globalFilter, setGlobalFilter]                              = useState('');
    const [selectedFlightsUser, setSelectedFlightsUser]                = useState(null);

    useEffect(() => {
        collectTheFlights(); 
      },[flightItem, processing])

      const collectTheFlights = async () => {
        try {
          
          const res = await axios.get(CONSTANTS.API_URL +"events/flights/list");
          setFlightListing(res.data);
          setflightListingArchive(res.data);
         // setGetTheEvents(true);
        } catch (err) {
          console.log(err);        
        }   
      }

      function handleTableFilter(event) {
        const newData = flightListArchive.filter(row => {  
              const searchText = event.target.value.toLowerCase();
  
              return (
                row.title.toLowerCase().includes(searchText) ||
                row.province.toLowerCase().includes(searchText) ||
                row.initial.toLowerCase().includes(searchText) 
               )
          });
  
          setFlightListing(newData);
    }
    //////////___
    const header = (
          <div className="table-header flex justify-content-between">
            <h4 className="mx-0 my-1"></h4>
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText type="search" onInput={handleTableFilter} placeholder="Search Script..." />
            </span>
          </div>
    );
    //////////___
    //////////___
    const linkFlightViewTextTemplate = (rowData) => {

        return     <Button
                        label="Edit Flight"
                        className="btn btn-rescure btsm1 mr-2"
                        onClick={() => updateEditTheFlight(rowData)}
                    />
    }
    //////////___

    const updateEditTheFlight = (rData) => {

        setFlightItem(rData);
        setShowEditFlightDetails(true);
    }
    //////////___
    const viewFlightListTemplate  = (rowData) => {
        return <div className="list-flight-options">
            {
                rowData.flightoption.map((option, index) => {
                return (
                    <p className="fala-flight" key={index}>
                    <span>{option.travelDate}</span>
                    <strong>{option.travelTime}</strong>
                    </p>
                )
                })
            }
        </div>
    }
    //////////___
  return (
    <div className="main-standard">
        <h2 className="lbl-title-page">Flights</h2>
        <div className="card">
            <div className="card-body">
              <DlgCreateFlight
                    setShowCreateFlightDetails={setShowCreateFlightDetails}
                    showCreateFlightDetails={showCreateFlightDetails}
                    processing={processing}
                    setProcessing={setProcessing}
                    person={user} 
                  />

              <DlgEditFlight
                  setShowEditFlightDetails={setShowEditFlightDetails}
                  showEditFlightDetails={showEditFlightDetails}
                  flightItem={flightItem}
                  person={user} 
                />
              
                <Button
                    label="Create Flight"
                    className="btn btn-rescure btsm1 mr-2"
                    onClick={() => setShowCreateFlightDetails(true)}
                    />

                    <div className="cont-agenda-slow pd10">
                        {
                            flightList && (
                                <DataTable
                                        value={flightList}
                                        header={header}
                                        className="min-w-full"
                                        paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Flights"
                                        responsiveLayout="scroll"
                                        globalFilter={(globalFilter) ? globalFilter : ''}
                                        selection={selectedFlightsUser}
                                        onSelectionChange={e => setSelectedFlightsUser(e.value)}
                                    >                      
                                        <Column field="title" header="Title"></Column>
                                        <Column field="province" header="Province"></Column>  
                                        <Column field="initial" header="Initials"></Column>  
                                        <Column header="Flight" body={viewFlightListTemplate}></Column>   
                                        <Column header="View" body={linkFlightViewTextTemplate}></Column>                        
                                </DataTable>
                            )
                        }
                </div>
            </div>
        </div>
    </div>
  )
}

export default Flights