import React, { useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { toast } from 'react-toastify';
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import { faThumbsUp, faThumbsDown, faUsers , faAdd} from '@fortawesome/free-solid-svg-icons'

function DlgCreateTradeShow({showTradeShowCreation, setShowTradeShowCreation, person, currentEvent}) {


  const [processing, setProcessing]                            = useState(false);
  const [msgFeed, setMsgFeed]                                  = useState('');
  const [msgFeedClass, setMsgFeedClass]                        = useState('');
  const [filteredUsers, setFilteredUsers]                      = useState([]);
  const [selectedUser, setSelectedUser]                        = useState(null);
  const [userfound, setUserFound]                              = useState(false);

  const nameRef                                    = useRef();
  const tradeShowImageToUploadRef                  = useRef();


  async function HandleCreatingTradeShow(e) {
    e.preventDefault();  
    setProcessing(true);   

    try{
  
      if(tradeShowImageToUploadRef.current.files[0]){

        const formData = new FormData();
        formData.append('tadeshowurl',  tradeShowImageToUploadRef.current.files[0]);

          const response = await axios.post(CONSTANTS.UPLOAD_MEVPHONE, formData);    
      
          if(response.status == 200){
            setMsgFeedClass("");
            const objecttadeShow = {
              "pdf_url"    : response.data.imageUrl,
              "name"       : nameRef.current.value,
              "eventcode"  : currentEvent.event_code,  
              "img_url"    : "",           
            }

            console.log(objecttadeShow);
            const content = await axios.post(CONSTANTS.API_URL+"events/tradeshow/creation", objecttadeShow, {
              headers: {
                   token: "Bearer "+ person.accessToken
               }
            });

           console.log(content.data);

            e.target.reset();
            toast.success("You successfully created an TradeShow.");
          }else {
            setMsgFeed("Something went wrong with uploading the image, please try again later ");
            setMsgFeedClass("alert-success");
          }
      }
  
       //setUserFound(false);
       setProcessing(false);   
      // 
    } catch (err){
      console.log(err);
      setProcessing(false);   
    }       
  }

  return (
    <Dialog header="Add TradeShow" visible={showTradeShowCreation} onHide={() => setShowTradeShowCreation(false)} style={{ width: '50vw' }}
      footer={
      <div>      
          <Button className="btn btn-picky" label="Close" onClick={() => setShowTradeShowCreation(false)} />
      </div>
      }>
    <div className="modal-body-content">
      <div className="form-item form-Even">                

            <form onSubmit={HandleCreatingTradeShow}>                   
                    <div className="form-group">
                          <div className="lbldesc">Title:</div>
                          <input type="text" className="form-control wide100" ref={nameRef} required/>
                    </div>
                    <div className="form-group">
                          Select PDF Map <small>(2 mb)</small>:
                        <input type="file" ref={tradeShowImageToUploadRef}  accept="application/pdf"required/>
                    </div>
   
                     <div className="form-bt-row">
                            <button type="submit" className="btn btn-rescure" disabled={processing}>Create</button>                             
                      </div>
            </form>
      </div>


        {
            processing && (
                <div className="img-source">
                   <img src={CONSTANTS.BLUE_PROCESSING} className="proc" />
                </div>
            )
        }
 
      </div>
    </Dialog>
  )
}

export default DlgCreateTradeShow