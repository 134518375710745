import React, { useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import { toast } from 'react-toastify';
import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,    
} from "chart.js";


function DlgChartBar({showBarChartDisp, setShowBarChartDisplay, currentEvent, person}) {
    const [processing, setProcessing]                             = useState(false);
    const [dataChartBar, setDataChartBar]                         = useState([]);
    const [refreshBar, setRefreshBar]                             = useState(0);


    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend,
    );

    useEffect(() => {

      if(currentEvent != null && currentEvent != undefined ){
      
        collectBarDetails();
      }
    },[currentEvent, refreshBar])
    
    const collectBarDetails = async () => {
        try{
            setProcessing(true);

            const eventData   = {
                "eventcode" : currentEvent.event_code,
                "colorcode" : currentEvent.colorcode,
            }
            const content = await axios.put(CONSTANTS.API_URL+"suppliers/graph/total/scans/", eventData, {
                headers:{
                    "token": "Bearer " + person.accessToken
                }
            });

            const lengthContent = content.data.length;
            const eventColor = currentEvent.colorcode;

            if(lengthContent > 0){
                var labelsList   = [];
                var dataSetList  = [];

                for (let i = 0; i < lengthContent ; i++) {
    
                    labelsList.push(content.data[i].name);
                    dataSetList.push(content.data[i].scanTotal);
                }
                              
                setDataChartBar({
                    labels: labelsList,
                    datasets: [
                        {
                            label: "Supplier Scans",
                            data: dataSetList,
                            backgroundColor: [eventColor],
                            borderWidth: 1
                        }
                    ]
                })
            }

            setProcessing(false);
        }catch(err){
            console.log(err);
            setProcessing(false);
        }
    }
    
    const option = {
        responsive: true,
        title: {
            display:true,
            text: "View report of total scans per session."
        }
    };

    const handleRefresh = () => {
        try{
            setRefreshBar( prev => prev + 1);
        }catch(err){
            console.log(err);
        }
    }

  return (
    <Dialog header="Supplier Bar Chart" visible={showBarChartDisp} onHide={() => setShowBarChartDisplay(false)} style={{ width: '70vw' }}
    footer={
         <div>      
            <Button className="btn btn-picky" label="Close" onClick={() => setShowBarChartDisplay(false)} />
          </div>
        }> 
        <div className="modal-body-content">
           <div className="report-bar">
        
             {
                dataChartBar && (
                    <Bar options={option} data={dataChartBar} />                   
                )
             }
       
           </div>
           <div className="pd10">
                <button className="btn btn-rescure btsm1" onClick={() => handleRefresh()}>Refresh</button>
           </div>
                {
                    processing && (
                        <div className="img-source">
                        <img src={CONSTANTS.ORANGE_PROCESSING} className="proc" />
                        </div>
                    )
                }
        </div>
     </Dialog>
  )
}

export default DlgChartBar