import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import * as CONSTANTS from "../CONSTANTS";
import { Button } from 'primereact/button';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import '../formstyle.css';
import DlgPharmacyAdd from '../Components/Dialogs/DlgPharmacyAdd';
import DlgPharmacyImport from '../Components/Dialogs/DlgPharmacyImport';

function Home() {
  const {user}                                                      = useSelector((state) => state.auth);
  const [currentIds, setCurrentIds]                                 = useState("");
  const [charge, setCharge]                                         = useState("");
  const [chargeNt,setChargeNt]                                      = useState("");

  const [updateCharge, setUpdateCharge]                             = useState(false);
  const [updateChargeNt, setUpdateChargeNt]                         = useState(false);

  const [flatRoom, setFlatRoom]                                     = useState("");
  const [flatFraUpgradeRoom, setFlatFraUpgradeRoom]                 = useState("");
  const [updateFlatRoom, setUpdateFlatRoom]                         = useState(false);
  const [updateFlatUpdrageRoom, setUpdateFlatUpdrageRoom]           = useState(false);

  const [gifts, setGiftsList]                                       = useState([]);
  const [addGifts, setAddGifts]                                     = useState(false);
  const [showVisitDial, setShowVisitDial]                           = useState(false);
  const [pharmacyList, setPharmacyList]                             = useState([]);
  const [visitsList, setVisitLists]                                 = useState([]);

  const [showBackendPharmacy, setShowBackendPharmacy]               = useState(false);
  const [showBackendPharmacyImport, setShowBackendPharmacyImport]   = useState(false);
  const [updateCount, setUpdateCount]                               = useState(0);
  
  const [processing, setProcessing]                                 = useState(false);

  const chargeItemRef                                   = useRef();
  const chargeItemNtRef                                 = useRef();
  const singleRoomRef                                   = useRef();
  const hotelInputRoomRef                               = useRef();


  const nameRef                                         = useRef();
  const extraInformationRef                             = useRef();
  const giftImageToUploadRef                            = useRef();
  const giftTypeInfoRef                                 = useRef();

  const dateVisitDateRef                                = useRef();
  const labelVisitRef                                   = useRef();
  const priceVisitRef                                   = useRef();

  useEffect(() => {    
    getGetGifts();
  
  },[])

  useEffect(() => {
    getStandardData();
    getVisitsDayList();
    
    if(processing === false){
      getGePharms();
    }
  },[processing])

  const getStandardData = async () => {

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: CONSTANTS.API_URL +"factories/standard/consume",
      headers: { }
    };

    const res = await axios.request(config);

    setCurrentIds(res.data[0]._id);
    setCharge(res.data[0].sfranchise_att);
    setChargeNt(res.data[0].sfranchise_notatt);
    setFlatRoom(res.data[0].single_room);
    setFlatFraUpgradeRoom(res.data[0].conference_upgrade);
  }

  const handleUpdateCharge = async (e) => {
    e.preventDefault();  
    setProcessing(true);   

    try{
      const putUpdate ={
        "currid": currentIds,
        "charge": chargeItemRef.current.value
      }
        const res = await axios.put(CONSTANTS.API_URL +"factories/update/charge", putUpdate,{
          headers: {
              token: "Bearer "+ user.accessToken
          }
      });
   
      toast.success("Charge has been updated.");
      chargeItemRef.current.value = "";
      setProcessing(false);  
    }catch(err){
      console.log(err);
      toast.error("Something went wrong, please try again later");
      setProcessing(false);  
    }
  }

  const handleUpdateChargeNt = async (e) => {
    e.preventDefault();  
    setProcessing(true);   

    try{
      const putUpdate ={
        "currid": currentIds,
        "chargent": chargeItemNtRef.current.value
      }
        const res = await axios.put(CONSTANTS.API_URL +"factories/update/charge/notattending", putUpdate,{
          headers: {
              token: "Bearer "+ user.accessToken
          }
      });
   
      toast.success("Charge has been updated.");
      chargeItemNtRef.current.value = "";
      setProcessing(false);  
    }catch(err){
      console.log(err);
      toast.error("Something went wrong, please try again later");
      setProcessing(false);  
    }
  }

  const handleUpdateRoom = async (e) => {
    e.preventDefault();  
    setProcessing(true);  
    try{
      const putUpdate ={
        "currid": currentIds,
        "room": singleRoomRef.current.value
      }
    const res = await axios.put(CONSTANTS.API_URL +"factories/update/single/room", putUpdate,{
            headers: {
                token: "Bearer "+ user.accessToken
            }
        });

        toast.success("Room price has been updated.");
        
        singleRoomRef.current.value ="";
        setProcessing(false);  
  } catch(err){
    console.log(err);
    toast.error("Something went wrong, please try again later");
    setProcessing(false);  
  }

  }
  const handleHotelUpdateRoom = async (e) => {
    e.preventDefault();  
    setProcessing(true);  
    try{

      const putUpdate ={
        "currid": currentIds,
        "upgrade": hotelInputRoomRef.current.value
      }

      const res = await axios.put(CONSTANTS.API_URL +"factories/update/hotel/standand/upgrade", putUpdate,{
            headers: {
                token: "Bearer "+ user.accessToken
            }
        });

        console.log(res.data);
       hotelInputRoomRef.current.value = "";
      setProcessing(false);  
    }catch(err){
      console.log(err);
      setProcessing(false);  
    }
  }
  const getGetGifts = async () => {

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: CONSTANTS.API_URL +"factories/gifting/consume",
      headers: { }
    };

    const res = await axios.request(config);

    console.log(res.data);
    setGiftsList(res.data);

  }

  const getVisitsDayList = async () => {
    try{

      const resVisit = await axios.get(CONSTANTS.API_URL +"factories/collect/visits");

      console.log(resVisit.data);
      setVisitLists(resVisit.data);

    }catch(err){
      console.log(err);
    }
  }
  const getGePharms = async () => {

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: CONSTANTS.API_URL +"factories/pharmacy/consume",
      headers: { }
    };

    const res = await axios.request(config);

    setPharmacyList(res.data);
  }

  async function HandleCreatingGift(e) {
    e.preventDefault();  
    setProcessing(true);   

    try{

      if(giftImageToUploadRef.current.files[0]){

        const formData = new FormData();
        formData.append('gift_url',  giftImageToUploadRef.current.files[0]);

          const response = await axios.post('https://mevent.co.za/meventphone/upload.php', formData);    
      
          if(response.status == 200){
          
            const objectGift = {
              "gift_url"   : response.data.imageUrl,
              "name"       : nameRef.current.value,
              "extrainfo"  : extraInformationRef.current.value,   
              "gift_type"  : giftTypeInfoRef.current.value,
            }

            console.log(objectGift);
            const content = await axios.post(CONSTANTS.API_URL+"factories/gift/create", objectGift);

            console.log(content.data);
            e.target.reset();
            toast.success("You successfully created an gift.");
          }else {
            toast.error("Something went wrong, please try again later.");
          }
      }
      //setUserFound(false);
      setProcessing(false);   
      // 
    } catch (err){
      console.log(err);
      setProcessing(false);   
    }       
  }

  async function HandleCreatingVisitDial(e) {
    e.preventDefault();  
    setProcessing(true);   
    try{

      const objectVisit = {
        "date"   : dateVisitDateRef.current.value,
        "label"  : labelVisitRef.current.value,   
        "price"  : priceVisitRef.current.value,
      }

      const content = await axios.post(CONSTANTS.API_URL+"factories/visits/create", objectVisit, {
        headers: {
             token: "Bearer "+ user.accessToken
         }
      });

      //console.log(content.data);
      dateVisitDateRef.current.value    = "";
      labelVisitRef.current.value       = "";   
      priceVisitRef.current.value       = "";
      setProcessing(false);   
    }catch(err){
      setProcessing(false);   
    }
  }
  const handleRemovePharmacy = async (pharm) => {
      setProcessing(true);

      try{

        const putUpdate ={
          "pharmacyid": pharm._id
        }
      const res = await axios.put(CONSTANTS.API_URL +"factories/delete/pharmacy", putUpdate,{
              headers: {
                  token: "Bearer "+ user.accessToken
              }
          });
        
        setProcessing(false);
      }catch(err){
        console.log(err);
        setProcessing(false);
      }

  };

  const dayVisitRemove = async (rem) =>{
    setProcessing(true);

    try{

      const putVisit ={
        "visitid": rem._id
      }

      const res = await axios.put(CONSTANTS.API_URL +"factories/delete/visit", putVisit,{
            headers: {
                token: "Bearer "+ user.accessToken
            }
        });
      
        dateVisitDateRef.current.value    = "";
        labelVisitRef.current.value       = "";   
        priceVisitRef.current.value       = "";
        
      setProcessing(false);
    }catch(err){
      console.log(err);
      setProcessing(false);
    }
  }
  return (
    <div className="main-standard">
      <h2 className="lbl-title-page">Home</h2>
      <div className="row-box">
               <DlgPharmacyAdd
                    setShowBackendPharmacy={setShowBackendPharmacy} 
                    showBackendPharmacy={showBackendPharmacy} 
                    person={user} 
                  />

              <DlgPharmacyImport  
                  showBackendPharmacyImport={showBackendPharmacyImport}
                  setShowBackendPharmacyImport={setShowBackendPharmacyImport}
                  person={user} 
                  />
            </div>
        <div className="card">
          <div className="card-body">
            <div className="row rs-card">
                  <div className="col-md-12">
                      <div className="rates-container">
                        <h2 className="bTitle2">Rates</h2>
                        <table className="table table-striped">
                            <tbody>
                              <tr>
                                <td className="wcel150">Accompanied by a spouse Charge Attending Clinic</td>
                                <td className="wcel15">:</td>
                                <td>R {charge}</td>
                                <td></td>
                                <td className="wid230">
                                  <Button
                                      label="Charge"
                                      className="btn btn-rescure btsm1 mr-2"
                                      onClick={() => setUpdateCharge(!updateCharge)}
                                    />
                                    {
                                      updateCharge && (
                                        <div className="civi-update">
                                                <form onSubmit={handleUpdateCharge}>   
                                                  <div className="form-group">
                                                        <div className="lbldesc">Set the Charge*:</div>
                                                        <input type="text" className="form-control wide100" ref={chargeItemRef} placeholder="Charge..." required/>
                                                    </div> 
                                                    
                                                    <div className="form-bt-row">
                                                        <button type="submit" className="btn btn-rescure" disabled={processing}>Update Charge</button>                             
                                                    </div>
                                                </form>
                                        </div>
                                      )
                                    }
                                    
                                </td>
                              </tr>
                              <tr>
                                <td className="wcel150">Accompanied by a spouse Charge Not attending Clinic</td>
                                <td className="wcel15">:</td>
                                <td>R {chargeNt}</td>
                                <td></td>
                                <td className="wid230">
                                  <Button
                                      label="Charge"
                                      className="btn btn-rescure btsm1 mr-2"
                                      onClick={() => setUpdateChargeNt(!updateChargeNt)}
                                    />
                                    {
                                      updateChargeNt && (
                                        <div className="civi-update">
                                                <form onSubmit={handleUpdateChargeNt}>   
                                                  <div className="form-group">
                                                        <div className="lbldesc">Set the Charge*:</div>
                                                        <input type="text" className="form-control wide100" ref={chargeItemNtRef} placeholder="Charge..." required/>
                                                    </div> 
                                                    
                                                    <div className="form-bt-row">
                                                        <button type="submit" className="btn btn-rescure" disabled={processing}>Update Charge</button>                             
                                                    </div>
                                                </form>
                                        </div>
                                      )
                                    }
                                    
                                </td>
                              </tr>
                              <tr>
                                <td className="wcel150">Single Room Clinic</td>
                                <td className="wcel5">:</td>
                                <td>R {flatRoom}</td>
                                <td></td>
                                <td className="wid50">
                                
                                <Button
                                      label="Room"
                                      className="btn btn-rescure btsm1 mr-2"
                                      onClick={() => setUpdateFlatRoom(!updateFlatRoom)}
                                    />
                                    {
                                      updateFlatRoom && (
                                        <div className="civi-update">
                                                <form onSubmit={handleUpdateRoom}>   
                                                  <div className="form-group">
                                                        <div className="lbldesc">Set the Charge*:</div>
                                                        <input type="text" className="form-control wide100" ref={singleRoomRef} placeholder="Room..." required/>
                                                    </div> 
                                                    
                                                    <div className="form-bt-row">
                                                        <button type="submit" className="btn btn-rescure" disabled={processing}>Update Room</button>                             
                                                    </div>
                                                </form>
                                        </div>
                                      )
                                    }
                                </td>
                              </tr>
                              <tr>
                                <td className="wcel150">Upgrade Hotel Amount for Franchise</td>
                                <td className="wcel5">:</td>
                                <td>R {flatFraUpgradeRoom}</td>
                                <td></td>
                                <td className="wid50">
                                
                                <Button
                                      label="Hotel Upgrade"
                                      className="btn btn-rescure btsm1 mr-2"
                                      onClick={() => setUpdateFlatUpdrageRoom(!updateFlatUpdrageRoom)}
                                    />
                                    {
                                      updateFlatUpdrageRoom && (
                                        <div className="civi-update">
                                                <form onSubmit={handleHotelUpdateRoom}>   
                                                  <div className="form-group">
                                                        <div className="lbldesc">Set the Amount*:</div>
                                                        <input type="text" className="form-control wide100" ref={hotelInputRoomRef} placeholder="Hotel..." required/>
                                                    </div>
                                                    <div className="form-bt-row">
                                                        <button type="submit" className="btn btn-rescure" disabled={processing}>Update Room</button>                             
                                                    </div>
                                                </form>
                                        </div>
                                      )
                                    }
                                </td>
                              </tr>
                            </tbody>
                          </table>
                      </div>
                  </div>
                  
                  <div className="col-md-5">
                    <div className="bs-box gifts-list-container">
                    <h2 className="bTitle2">Gifts</h2>
                        <div className="view-gifts-list">
                            {
                              gifts && (
                                <ul>
                                  {
                                    gifts.map((gift, index) => {
                                      return (<li key={index}>
                                        <div className="">
                                          <span className="bold">{gift.name}</span> -<span>{gift.gift_type}</span>
                                        </div>
                                      </li>)
                                    })
                                  }
                                </ul>
                              )
                            }
                        </div>
                        <div className="add-gifts-item">
                        <Button
                                  label="Add Gift"
                                  className="btn btn-rescure btsm1 mr-2"
                                  onClick={() => setAddGifts(!addGifts)}
                                />
                          {
                            addGifts && (
                                <div className="pd10 group-form-add">
                                   <form onSubmit={HandleCreatingGift}>                   
                                      <div className="form-group">
                                            <div className="lbldesc">Title:</div>
                                            <input type="text" className="form-control wide100" ref={nameRef} required/>
                                      </div>
                                      <div className="form-group">
                                        <div className="lbldesc">Extra information</div>
                                        <textarea className="form-control" ref={extraInformationRef}></textarea>
                                      </div>
                                      <div className="form-group">
                                        <div className="lbldesc"></div>
                                        <select className="form-control" ref={giftTypeInfoRef}>
                                           <option value="Clinic">Clinic</option>
                                           <option value="Franchise">Franchise</option>
                                        </select>
                                      </div>
                                      <div className="form-group">
                                            Select image to upload <small>(2 mb)</small>:
                                          <input type="file" ref={giftImageToUploadRef} multiple="" accept="image/*" required/>
                                      </div>
                    
                                      <div className="form-bt-row">
                                              <button type="submit" className="btn btn-rescure" disabled={processing}>Create</button>                             
                                        </div>
                                  </form>
                                </div>
                              )
                          }
                        </div>
                    </div>
                  </div>
                 
                  <div className="col-md-7">
                    <div className="bs-box visits-list-container">
                    <h2 className="bTitle2">Day Visits</h2>
                        <div className="view-gifts-list">
                            {
                              visitsList && (
                                <ul>
                                  {
                                    visitsList.map((visit, index) => {
                                      return (<li key={index}>
                                        <div className="flexme visittop">
                                          <div className="flexwidth90">
                                          <span className="bold">{visit.date}</span> - <span>{visit.label}</span> ({visit.price})
                                          </div>
                                          <button type="button" class="btn btn-close-ls posi-close small-close " onClick={() => dayVisitRemove(visit)}>X</button>
                                        </div>
                                      </li>)
                                    })
                                  }
                                </ul>
                              )
                            }
                        </div>
                        <div className="add-gifts-item">
                         
                           <Button
                                label="Add Visit Date"
                                className="btn btn-rescure btsm1 mr-2"
                                onClick={() => setShowVisitDial(!showVisitDial)}
                              />
                          {
                            showVisitDial && (
                                <div className="pd10 group-form-add">
                                   <form onSubmit={HandleCreatingVisitDial}>                   
                                      <div className="form-group">
                                            <div className="lbldesc">Date:</div>
                                            <input type="text" className="form-control wide100" ref={dateVisitDateRef} required/>
                                      </div>
                                      <div className="form-group">
                                        <div className="lbldesc">Label</div>
                                        <input type="text" className="form-control wide100" ref={labelVisitRef} required/>
                                      </div>
                                      <div className="form-group">
                                        <div className="lbldesc">Price</div>
                                        <input type="text" className="form-control wide100" ref={priceVisitRef} required/>
                                      </div>
                    
                                      <div className="form-bt-row">
                                              <button type="submit" className="btn btn-rescure" disabled={processing}>Create</button>                             
                                        </div>
                                  </form>
                                </div>
                              )
                          }
                        </div>
                    </div>
                  </div>
            </div>
            <div className="row rs-card">
       
              <div className="col-md-6">
                  <div className="pharmacies-container">
                    <h2 className="bTitle2">Pharmacies</h2>
                    {
                      pharmacyList ? (
                        <ul className="no-style">
                          {
                            pharmacyList.map((pharm, index) => {
                              return (<li key={index} className="pharm-item">
                                <div className="item-text">
                                  {pharm.name}
                                </div>
                                <button type="button" className="btn btn-close-ls posi-close " onClick={() => handleRemovePharmacy(pharm)}>
                                        X
                                 </button>
                                </li>)
                            })
                          }
                        </ul>
                      )
                      :
                          <div className="message-out">
                            <h3>No Pharmacies have been added.</h3>
                          </div>
                    }
                  </div>
              </div>
              <div className="col-md-6">
                    <div className="add-pharm-item">
                        <Button
                            label="Import Pharmacies"
                            className="btn btn-rescure btsm1 mr-2"
                            onClick={() => setShowBackendPharmacyImport(true)}
                          />

                         <Button
                            label="Create Pharmacy"
                            className="btn btn-rescure btsm1 mr-2"
                            onClick={() => setShowBackendPharmacy(true)}
                          />
                    </div>
              </div>
            </div>
          </div>
        </div>
        {
            processing && (
                <div className="img-source">
                   <img src={CONSTANTS.ORANGE_PROCESSING} className="proc" />
                </div>
            )
        }
    </div>
  )
}

export default Home