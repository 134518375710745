import React, { useEffect,useRef, useState } from 'react';
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
import { toast } from 'react-toastify';
import { firestore , collection, getDocs, admin} from '../service/firebaseInit'; 


function Notifications() {

    const [processing, setProcessing]                            = useState(false);
    const [tokenList, setTokenList]                              = useState([]);
    const [msgProcText, setMsgProcessText]                       = useState("");
    const [notifyCount, setNotifyCount]                          = useState(0);

    const subjectRef                                             = useRef();
    const messagedRef                                            = useRef();

    useEffect(() => {
     collectAllUserTokens();
     },[]);

     const collectAllUserTokens = async () => {
      try {

        const tokens = [];
        // Reference the 'userToken' collection
        const userTokenCollectionRef = collection(firestore, 'UserTokens');
        
        // Get all documents within the 'userToken' collection
        const snapshot = await getDocs(userTokenCollectionRef);

        // Loop through each document in the snapshot
        snapshot.forEach((doc) => {

          const varialble = {
            "user" : doc.id.replace("User",""),
            "token" : doc.data().token
          }
          tokens.push(varialble);
        
        });
        setTokenList(tokens);

      } catch (error) {
        console.error('Error fetching user tokens: ', error);
      }
   }

  async function HandleCreatingNotificaiton(e) {
    e.preventDefault();  
    setProcessing(true);   

    try{
  
            const objectNotify = {
              "title"       : subjectRef.current.value,
              "description" : messagedRef.current.value,           
            }
            tokenList.forEach(async (tokenItem) => {
                 sendPushNotificaiton(objectNotify, tokenItem);
            })

            toast.success("Succefully sent notifications.");
       setProcessing(false);   
        subjectRef.current.value   = "";
        messagedRef.current.value  = "";

    } catch (err){
      console.log(err);
      toast.error("Something went wrong, please try again later");
      setProcessing(false);   
    }       
  }

  const sendPushNotificaiton = async (pushNotify, tokenItem) => {
    /*
    const URLFIREBACE_API = "https://fcm.googleapis.com/v1/projects/meventphonetlc/messages:send";
    const FCMaccessToken = "d1cd5df20d09290d6711d9eaf523575905293136";
    */
    const notiID = "24" + Math.floor(Math.random() * 100000);

      const message = {
        "token": tokenItem.token,
        "notification": {
          "title": pushNotify.title,
          "body": pushNotify.description,
        },
        "data": {
          "notifyid" : notiID,
          "phone" : tokenItem.user
        }
      }  
     
  
    const content = await axios.post(CONSTANTS.API_URL + "notifications/sending", message);
    console.log("*****************");
    console.log(content);
    console.log("*****************");
    setMsgProcessText(tokenItem.user);

    if(content.status == 200){
      setNotifyCount(prevCount => prevCount + 1);
    }

  }

  return (
    <div className="main-standard">
       <h2 className="lbl-title-page">Notifications</h2>
        <div className="card">
            <div className="card-body">
               <div className="row-data">
                  <div className="flexme">
                    <div className="flexwidth50">
                       <div className="form-item form-Notify">                

                            <form onSubmit={HandleCreatingNotificaiton}>                   
                                    <div className="form-group">
                                        <div className="lbldesc">Subject:</div>
                                        <input type="text" className="form-control wide100" ref={subjectRef} required/>
                                    </div>
                                    <div className="form-group">
                                        <div className="lbldesc">Message:</div>
                                        <textarea className="form-control wide100" ref={messagedRef} required></textarea>
                                    </div>

                                    <div className="form-bt-row">
                                            <button type="submit" className="btn btn-rescure" disabled={processing}>Create</button>                             
                                    </div>
                            </form>
                        </div>
                    </div>
                    <div className="flexwidth50">
                        <div className="text-center alert-back pd10">
                          {
                            msgProcText && (
                              <div className="kabong">
                                {msgProcText}
                              </div>
                            )
                          }
                        </div>
                        <div className="pd10">
                            {
                              (notifyCount > 0) && (                             
                                  <div className="text-center"> <span className="noti-ct">{notifyCount}</span> <span> total people who recieved the notifications.</span></div>                             
                              )
                            }
                        </div>
                    </div>

                  </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Notifications